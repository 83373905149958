import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../helper/dimension";
import Toast from "./toast/toast";
import { Btn, EditText, ErrorCont, LrText, Mgin, StripBtn, goUrl, myEles } from "../helper/general";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { bootcampEle } from "../helper/classes";
import { getFS, getQuery, getUid, setFS } from "../helper/firehelpers";
import { collection, doc as docu, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { Close } from "@mui/icons-material";


export default function Bootcamps(mainprop:{isNgt:boolean}){
    const dimen = useWindowDimensions()
    const navigate = useNavigate();
    const mye = new myEles(mainprop.isNgt);
    const mykey = Date.now()
    const[isMobile, setIsMobile] = useState(true)
    const[askForCode, setAskForCode] = useState(false)
    const[showregform, setShowRegForm] = useState(false)
    const[bootcamp, setBootcamp] = useState<bootcampEle>()
    const[bootcamps, setBootcamps] = useState<bootcampEle[]>([])
    const[bootcampCode,setBootcampCode] = useState(localStorage.getItem('bcc') ?? '')
    //const[cid, setCid] = useState('')

    useEffect(()=>{
        setIsMobile(!dimen.dsk)
        getBCP();
    },[])

    function getBCP(){
        setLoad(true)
        setError(false)
        getQuery(collection(getFirestore(),`bootcamps`),(task)=>{
            setLoad(false)
            if(task.isSuccessful()){
                let tem:bootcampEle[] = []
                task.getResult().docs.forEach((doc)=>{
                    tem.push(new bootcampEle(doc))
                })
                setBootcamps(tem)
            }else{
                setError(true)
            }
        })
    }

    const[load, setLoad]=useState(false)
    const[error, setError]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    function toast(msg:string, action:number,delay?:number){
      var _delay = delay || 5000
      setToastMeta({
          action: action,
          msg: msg,
          visible:true,
          invoked: Date.now()
      })
      clearTimeout(timy.timer)
      setTimy({
          timer:setTimeout(()=>{
              if(Date.now()-toastMeta.invoked > 4000){
                  setToastMeta({
                      action:2,
                      msg:"",
                      visible:false,
                      invoked: 0
                  })
              }
          },_delay)
      });
    }

    return (<div style={{
        backgroundColor:'lavender',
        width:'100%',
        height:'100%'
    }}  data-theme={mainprop.isNgt?'dark':'light'}>
        {bootcamps.length>0?<div style={{
            display:"flex",
            flexWrap:'wrap'
        }}>
            {
                bootcamps.map((ele,index)=>{
                    return <Course ele={ele} key={mykey+1+index} />
                })
            }
        </div>:<div className="ctr" style={{
            width:'100%',
            height:'100%'
        }}>
            <mye.Tv text="No bootcamps yet, check back later" />
            </div>}
        <div style={{
            display:(askForCode && bootcamp)?'flex':'none',
            alignItems:'center',
            justifyContent:'center',
            backgroundColor:'rgba(0,0,0,0.1)',
            position:'absolute',
            top:0,
            left:0,
            width:'100%',
            height:'100%'
        }}>
            <div style={{
                width:dimen.dsk?300:250,
                padding:20,
                backgroundColor:mye.mycols().white,
                borderRadius:20,
            }}>
                <mye.BTv color={mye.mycols().primarycol} size={18} text="Please Note" />
                <Mgin top={10}/>
                <mye.Tv size={12} text="You will be forwarded to our e-learning partner (playform). You will need to create an account if you haven't already." />
                <Mgin top={10}/>
                <EditText hint={showregform?'':"Enter bootcamp code"} value={bootcampCode} isNgt={mainprop.isNgt} recv={(v)=>{
                    setBootcampCode(v.trim())
                }}  />
                <Mgin top={10}/>
                <Btn txt="Okay, Proceed" onClick={()=>{
                    if(!bootcamp){
                        return
                    }
                    if(bootcampCode.length==0){
                        toast('Please enter bootcamp code or click GET BOOTCAMP CODE',0)
                        return
                    }
                    if(bootcampCode != bootcamp.getCode()){
                        toast('Invalid bootcamp code. Click GET BOOTCAMP CODE',0)
                        return
                    }
                    goUrl(bootcamp.getLink()+`?eml=${getAuth().currentUser?.email || ''}`)
                    setAskForCode(false)
                }} />
                <Mgin top={5}/>
                <mye.Tv text="Dont have it?" size={12} />
                <Mgin top={5}/>
                <StripBtn txt="GET BOOTCAMP CODE" onClick={()=>{
                    setShowRegForm(true)
                }} />
            </div>
        </div>
        <div style={{
            display:(showregform && bootcamp)?'flex':'none',
            alignItems:'center',
            justifyContent:'center',
            backgroundColor:'rgba(0,0,0,0.1)',
            position:'absolute',
            top:0,
            left:0,
            width:'100%',
            height:'100%'
        }}>
            <div style={{
                display:'flex',
                flexDirection:'column',
                width:isMobile?'100%':600,
                height:isMobile?'100%':dimen.height-200,
                backgroundColor:mye.mycols().white,
                borderRadius:isMobile?0:20,
                position:'relative'
            }}>
                <Mgin top={20} />
                <LrText left={<div className="hlc">
                    <Mgin right={10} />
                    <mye.BTv text={' Register'}size={20}/>
                </div>} right={<div className="hlc">
                    <Close className="icon" onClick={()=>{
                    setShowRegForm(false);
                     }} />
                     <Mgin right={10} />
                </div>} />
                <Mgin top={10} />
                <div style={{
                    flex:1
                }}>
                    <iframe src={bootcamp?bootcamp.getRegForm():''} 
                width="100%" height="100%" frameBorder={"0"} title={bootcamp?bootcamp.getTitle():''}  >Loading…</iframe>
                </div>
                <Mgin top={20} />
            </div>
        </div>
        <ErrorCont isNgt={mainprop.isNgt} visible={error} retry={()=>{
            getBCP()
        }}/>
        <div className="prgcont" style={{display:load?"flex":"none"}}>
            <CircularProgress/>
        </div>
        <Toast isNgt={mainprop.isNgt} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                setToastMeta({
                    action:2,
                    msg:"",
                    visible:false,
                    invoked:0,
                })
            }} />
    </div>
    )

    function Course(prop:{ele:bootcampEle}){
        const[url, setUrl] = useState('')

        useEffect(()=>{
            prop.ele.getPic((u)=>{
                setUrl(u)
            })
        },[])

        return <div style={{
            backgroundColor:mye.mycols().white,
            borderRadius:20,
            width:isMobile?'100%':'300px'
        }}>
            <img src={url} alt={prop.ele.getTitle()} style={{
                borderRadius:'20px 20px 0px 0px',
                width:'100%'
            }} />
            <div style={{
                padding:20,
            }}>
                <mye.BTv size={16} text={prop.ele.getTitle()} color={mye.mycols().hint} />
                <Mgin top={5}/>
                <mye.Tv size={12} color={mye.mycols().hint} text={prop.ele.getDesc()} />
                <Mgin top={10}/>
                <Btn txt={`Start Learning`} onClick={()=>{
                    setBootcamp(prop.ele)
                    setAskForCode(true)
                }} />
            </div>
        </div>

    }

}