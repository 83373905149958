//import logo from './logo.svg';
import './App.css';
import { FirebaseApp, initializeApp } from "firebase/app";
import {BrowserRouter as Router, Routes, Route, useLocation, useParams, useNavigate} from 'react-router-dom'
import { CircularProgress, createTheme, ThemeProvider } from "@mui/material";
import { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import SignIn from './components/signin';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, UserCredential } from 'firebase/auth';
import Damain from './components/damain';
import Privacy from './components/privacy';
import { BoldText, Btn, EditText, isEmlValid,  Mgin, MyCols, TextBox } from './helper/general';
import useWindowDimensions from './helper/dimension';
import Toast from './components/toast/toast';
import { collection, DocumentSnapshot, getFirestore, doc as docu } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFS, getQuery } from './helper/firehelpers';
import AuthMe from './components/auth';

let playformConfig = {
    apiKey: "AIzaSyBY_RzAkduGJzROnwVgKzxHcoEOQhJwVQ4",
    authDomain: "playform-felix.firebaseapp.com",
    projectId: "playform-felix",
    storageBucket: "playform-felix.appspot.com",
    messagingSenderId: "840048146513",
    appId: "1:840048146513:web:b1527ff35820ce3d8fe070",
    measurementId: "G-WZRS09M574"
  }
let monagreeConfig = {
  apiKey: "AIzaSyDS9elTMRcf7RGArd7EG60S_3wLLCL5j_w",
  authDomain: "monagree-apps.firebaseapp.com",
  projectId: "monagree-apps",
  storageBucket: "monagree-apps.appspot.com",
  messagingSenderId: "518533082849",
  appId: "1:518533082849:web:9c072ca56f0d585e4b51a0",
  measurementId: "G-KW4KV9BF8B"
  }
initializeApp(monagreeConfig)
let playformApp = initializeApp(playformConfig,"playform")

  const theme = createTheme({
    palette: {
        primary:{
            main: '#0411A7'
        },
        secondary:{
            main:'#FFA500'
        },
    }
})

function useQuery(){
  return new URLSearchParams(useLocation().search);
}


export default function App() {
  const[isNgt, setNgt] = useState(false)
  const[isApp, setIsApp] = useState(true)
  //const qry = useQuery();

  useEffect(()=>{
    let murl = window.location.href;
    if(murl.includes('mode=')){
      let url = murl.split('mode=')[1]
      if(url.startsWith('n')){
        setNgt(true)
      }
    }
    setIsApp(murl.includes('app='))
  },[])
 
  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <Router>
          <Routes>
          <Route path='/'  element={<Damain isApp={isApp} isNgt={isNgt} playform={playformApp} />}></Route>
            <Route path='/signin'  element={<SignIn isApp={isApp} isNgt={isNgt}/>}></Route>
            <Route path='/auth/:aid/:mtk'  element={<AuthMe isApp={isApp} isNgt={isNgt}  />}></Route>
            <Route path='/privacy'  element={<Privacy />}></Route>
          </Routes>
        </Router>
      </div> 
    </ThemeProvider>
  );
}

