
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../helper/dimension";
import Toast from "./toast/toast";
import { ErrorCont, Mgin, myEles } from "../helper/general";
import { memo, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { designEle, profileEle } from "../helper/classes";
import { getQuery } from "../helper/firehelpers";
import { collection, getFirestore } from "firebase/firestore";
import { DeliveryDining, Shop, ShoppingCart, Verified } from "@mui/icons-material";
import { isMobile } from "react-device-detect";


export default function Designs(mainprop:{isNgt:boolean,profile:profileEle,move:(pos:number)=>void}){
    const [ran, setRan] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const dimen = useWindowDimensions()
    const navigate = useNavigate();
    const mye = new myEles(mainprop.isNgt);
    const[designs, setDesigns] = useState<designEle[]>([])
    const mykey = Date.now()

    useEffect(()=>{
        if(!ran){
            setRan(true)
            setIsMobile(!dimen.dsk)
            getDesigns()
        }
    },[])

    function getDesigns(){
        setLoad(true)
        setError(false)
        getQuery(collection(getFirestore(),`${mainprop.profile.doc.ref.path}/designs`), (task)=>{
            setLoad(false)
            if(task.isSuccessful()){
                let tem:designEle[] = []
                task.getResult().docs.forEach((doc, index)=>{
                    tem.push(new designEle(doc))
                })
                setDesigns(tem)
            }else{
                setError(true)
            }
        })
    }


    const[load, setLoad]=useState(false)
    const[error, setError]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    function toast(msg:string, action:number,delay?:number){
      var _delay = delay || 5000
      setToastMeta({
          action: action,
          msg: msg,
          visible:true,
          invoked: Date.now()
      })
      clearTimeout(timy.timer)
      setTimy({
          timer:setTimeout(()=>{
              if(Date.now()-toastMeta.invoked > 4000){
                  setToastMeta({
                      action:2,
                      msg:"",
                      visible:false,
                      invoked: 0
                  })
              }
          },_delay)
      });
    }

    return (<div style={{
        backgroundColor:'lavender',
        width:'100%',
        height:'100%',
    }}  data-theme={mainprop.isNgt?'dark':'light'}>
        <ErrorCont isNgt={mainprop.isNgt} visible={error} retry={()=>{
            getDesigns()
        }}/>
        <div className="prgcont" style={{display:load?"flex":"none"}}>
            <CircularProgress/>
        </div>
        <Toast isNgt={mainprop.isNgt} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                setToastMeta({
                    action:2,
                    msg:"",
                    visible:false,
                    invoked:0,
                })
            }} />
        <div className="vlc">
            {
                designs.length>0?<div style={{
                    display:'flex',
                    flexWrap:'wrap'
                }}>
                    {
                        designs.map((design,index)=>{
                            return <DesignMini ele={design} isMobile={isMobile} mye={mye}  />
                        })
                    }
                </div>:<div className="ctr" style={{
                    width:'100%',
                    height:200
                }}>
                    <div className="ctr" style={{
                        width:isMobile?'70%':'50%'
                    }}>
                        <mye.Tv text="Noting Yet. Designs are created using the command line tool (see docs)" center color={mye.mycols().hint}/>
                    </div>
                </div>
            }
            <Mgin top={40} />
            <mye.BTv text="Build Something Awesome" color={mye.mycols().primarycol} center size={24} font={'Pacifico'}/>
            <Mgin top={20} />
            {/*Place with guide video*/}
            <iframe style={{
                    borderRadius:"10px",
                    margin:10
                }} width={!isMobile?500:'100%'} height={280} src={'https://www.youtube.com/embed/BsAVhuMFOAI?si=R2TpM21S9SnL8BTd'} 
                title={'Getting Started With Monagree Devs'} frameBorder="0" ></iframe>
            <div style={{
                display:'flex',
                flexWrap:'wrap'
            }}>
                <Hinty pos={0} tabId={2} head="API" desc="Get your API keys and start building worldwide" />
                <Hinty pos={1} tabId={3} head="Code" desc="Test codes right here on the dashboard" />
                <Hinty pos={2} tabId={4} head="Docs" desc="Easy to understand developer documentation" />
                <Hinty pos={3} tabId={5} head="Bootcamps" desc="Free, Virtual & Certified bootcamps to get started" />
                <Hinty pos={4} tabId={7} head="Community" desc="Learn from other Monagree Developers" />
            </div>
        </div>
        
        <div className="ctr" style={{
            width:'100%',
            marginTop:20
        }}>
            <mye.Tv text="Design with Love" color={mye.mycols().hint} size={12} font={'Pacifico'}/>
        </div>
    </div>
    )

    function Hinty(prop:{pos:number,tabId:number,head:string,desc:string}){
        const bcols = ['black','magenta','purple','red','blue']
        let i = prop.pos % 4
        return <div id="clk" style={{
            width:isMobile?'100%':200,
            padding:20,
            boxSizing:'border-box',
            backgroundColor:bcols[i],
            borderRadius:10,
            margin:10
        }} onClick={()=>{
            mainprop.move(prop.tabId)
        }}>
            <Mgin top={20} />
            <mye.BTv text={prop.head} size={18} color="white" />
            <Mgin top={10} />
            <mye.Tv text={prop.desc} size={12} color="white" maxLines={3} />
        </div>
    }



}

const DesignMini = memo((prop:{isMobile:boolean,ele:designEle,mye:myEles})=>{
    const mykey = Date.now()
    const[iUrl, setIurl] = useState('')
    const[subcats, setCats] = useState<string[]>([])

    useEffect(()=>{
        // prop.ele.getPic((url)=>{
        //     setIurl(url)
        // })
        prop.ele.getCats((cts)=>{
            setCats(cts)
        })
    },[])


    return <div style={{
        width:isMobile?'100%':250,
        height:300,
        margin:10,
        backgroundColor:prop.mye.mycols().white,
        borderRadius:10,
        display:'flex',
        flexDirection:'column',
        position:'relative'
    }}>
        <img src={iUrl} alt={''} style={{
            flex:1,
            width:'100%',
            borderRadius:'10px 10px 0 0',
            objectFit:'cover',
        }} />
        <div style={{
            flex:1,
            margin:10,
            overflow:'scroll'
        }}>
            <Mgin top={10} />
            <prop.mye.BTv text={prop.ele.getTitle()}  size={16}/>
            <Mgin top={5} />
            <prop.mye.Tv text={prop.ele.getDesc()} maxLines={3}  color={prop.mye.mycols().hint} size={12}/>
            <Mgin top={3} />
            <div className="flexi">
                {
                subcats.map((cat,index)=>{
                    return <CatMini title={cat} key={mykey+index+0.223} i={index} />
                })    
                }
            </div>
        </div>
        <div style={{
            display:'flex',
            flexDirection:'column',
            width:'100%',
            position:'absolute',
            top:0,
            left:0,
            height:'100%'
        }}>
            <div style={{
                flex:1,
                width:'100%',
                borderRadius:'10px 10px 0 0',
                backgroundColor:'rgba(0,0,0,0.1)'
            }}>
            </div>
            <div style={{
                flex:1
            }}>
            </div>
        </div>
        <div className="hlc" style={{
            position:'absolute',
            top:0,
            right:0,
            margin:12,
            padding:5,
            boxSizing:'border-box',
            borderRadius:50,
            backgroundColor:prop.mye.mycol.white
        }}>
            <div style={{
                marginLeft:5
            }}>
                {
                    prop.ele.isStore()?<ShoppingCart style={{
                        fontSize:18,
                        color:prop.mye.mycol.primarycol
                    }} />:<DeliveryDining style={{
                        fontSize:18,
                        color:prop.mye.mycol.primarycol
                    }} />
                }
            </div>
            <Mgin right={5} />
            <Verified  style={{
                fontSize:12,
                color:prop.ele.isPremiuim()?prop.mye.mycol.primarycol:prop.mye.mycol.imghint,
            }} />
        </div>
    </div>

    function CatMini(pp:{title:string,i:number}) {
        const bcols = ['black','magenta','purple','red','blue']
        let pos = pp.i % 4
        return <div style={{
            backgroundColor:bcols[pos],
            padding:3,
            borderRadius:5,
            margin:3
        }}>
            <prop.mye.Tv text={pp.title} size={12} color="white"/>
        </div>
    }

})


