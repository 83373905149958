import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../helper/dimension";
import Toast from "./toast/toast";
import { ErrorCont, Mgin, myEles } from "../helper/general";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { actEle, getWho, patronEle, profileEle } from "../helper/classes";
import { getUid, setFS } from "../helper/firehelpers";
import { collection, doc as docu, documentId, getDocs, getFirestore, limit, orderBy, query, updateDoc, where } from "firebase/firestore";
import { ReAuth } from "./signin";
import { Business, ContentCopy, Share, Visibility, VisibilityOff } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid"


export default function Api(mainprop:{isNgt:boolean,profile:profileEle}){
    const [ran, setRan] = useState(false)
    const [patred, setPatred] = useState(false)
    const [actred, setActred] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const dimen = useWindowDimensions()
    const navigate = useNavigate();
    const mye = new myEles(mainprop.isNgt);
    const mykey = Date.now()
    const[patrons,setPatrons] = useState<patronEle[]>([])
    const[acts,setActs] = useState<actEle[]>([])

    useEffect(()=>{
        if(!ran){
            setRan(true)
            setIsMobile(!dimen.dsk)
            begin()
        }
    },[])

    function begin(){
        //--Short Code
        if(mainprop.profile.getShortCode().length==0){
            genSC()
        }else{
            proceed()
        }
    }

    function genSC(){
        setLoad(true)
        const sc = localStorage.getItem('sc') ?? uuidv4().substring(0, 7)
        setFS(docu(getFirestore(),`sc/${sc}`),{
            k:mainprop.profile.getId()
        },(task)=>{
            setLoad(false)
            if(task._success){
                updateDoc(mainprop.profile.doc.ref,{
                    s:sc
                }).then(()=>{
                    window.location.reload()
                }).catch((e)=>{
                    setError(true)
                })
            }else{
                setError(true)
            }
        })
    }

    function proceed(){
        //Patrons
        getDocs(query(collection(getFirestore(),`patrons`),where(
            'd','==',getUid()
        ))).then((docs)=>{
            const tem:patronEle[] = []
            docs.docs.forEach((doc, index)=>{
                const ele = new patronEle(doc)
                tem.push(ele)
            })
            setPatrons(tem)
            setPatred(true)
        }).catch((e)=>{
            console.error(e)
        })
        getDocs(query(collection(getFirestore(),`sc/${mainprop.profile.getShortCode()}/acts`),
        orderBy(documentId(),'desc'),limit(10))).then((docs)=>{
            const tem:actEle[] = []
            docs.docs.forEach((doc)=>{
                tem.push(new actEle(doc))
            })
            setActs(tem)
            setActred(true)
        }).catch((e)=>{
            console.error(e)
        })

    }



    const[load, setLoad]=useState(false)
    const[error, setError]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    function toast(msg:string, action:number,delay?:number){
      var _delay = delay || 5000
      setToastMeta({
          action: action,
          msg: msg,
          visible:true,
          invoked: Date.now()
      })
      clearTimeout(timy.timer)
      setTimy({
          timer:setTimeout(()=>{
              if(Date.now()-toastMeta.invoked > 4000){
                  setToastMeta({
                      action:2,
                      msg:"",
                      visible:false,
                      invoked: 0
                  })
              }
          },_delay)
      });
    }

    return (<div style={{
        backgroundColor:'lavender',
        width:'100%',
        height:'100%'
    }}  data-theme={mainprop.isNgt?'dark':'light'}>
        <ErrorCont isNgt={mainprop.isNgt} visible={error} retry={()=>{
            begin()
        }}/>
        <div className="prgcont" style={{display:load?"flex":"none"}}>
            <CircularProgress/>
        </div>
        <Toast isNgt={mainprop.isNgt} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                setToastMeta({
                    action:2,
                    msg:"",
                    visible:false,
                    invoked:0,
                })
            }} />
        <div className="vlc">
            <Mgin top={20} />
            <div className="flexi">
                <TopMini title="Dev ID" txt={mainprop.profile.getShortCode()} sharable />
                <TopMini title="API Key" txt={mainprop.profile.doc.id} sharable={false} />
            </div>
            <Mgin top={30} />
            <div className="flexi" style={{
                width:'100%'
            }}>
                <PatronLay eles={patrons} rdy={patred} />
                <ActLay eles={acts} rdy={actred} />
            </div>
            <Mgin top={30} />
            <mye.Tv text="Share your dev id with businesses to gain access to build their website using the API" size={12} color={mye.mycols().hint} />
            <Mgin top={10} />
            <mye.Tv text="Do not share your API key with anyone" size={12} color={mye.mycols().black} />
        </div>
    </div>
    )

    function TopMini(prop:{title:string,txt:string,sharable:boolean}){
        const[shIt, setShIt] = useState(false)
        const [authMe, setAuthMe] = useState(false)
        const [authed, setAuthed] = useState(false)
        const[action,setAction] = useState(0)

        let nvs = ''
        for(let i=0;i<prop.txt.length;i++){
            nvs+='*'
        }
        if(isMobile){
            nvs = '******'
        }

        return <div>
            <div className="shdw" style={{
                backgroundColor:mye.mycols().primarycol,
                borderRadius:10,
                padding:10,
                margin:10,
            }}>
                <mye.Tv text={prop.title} size={12} color={mye.mycols().white} />
                <div style={{
                     display:'flex',
                    alignItems:"center"
                }}>
                    <mye.BTv text={shIt?prop.txt:nvs} size={30} color={mye.mycols().white} />
                    <Mgin right={10}/>
                    <Bt pos={0}/>
                    <Bt pos={1}/>
                    <div style={{
                        display:prop.sharable?undefined:'none'
                    }}>
                        <Bt pos={2}/>
                    </div>
                </div>
            </div>
            {authMe?<ReAuth isMobile={isMobile} mye={mye} finise={(ok)=>{
                setAuthMe(false)
                if(ok){
                    setAuthed(true)
                    if(action==0){
                        setShIt(!shIt)
                    }
                    if(action==1){
                        navigator.clipboard.writeText(prop.txt)
                    }
                    if(action==2){
                        const url = `https://business.monagree.com/linkdev/${prop.txt}`
                        if(navigator.share){
                            navigator.share({
                                title:'Link a Developer',
                                text:`Link ${mainprop.profile.getName()} to your business as a developer`,
                                url:url
                            })
                        }else{
                            navigator.clipboard.writeText(url)
                            window.alert('Your browser does not support share, so we have copied the link to your clipboard instead')
                        }
                    }
                    
                }
            }} />:<div></div>}
        </div>

        function Bt(pp:{pos:number}) {
            const sty = {
                fontSize:20,
                color:mye.mycols().primarycol
            }
            return <div className="ctr" style={{
                width:40,
                height:40,
                padding:5,
                borderRadius:5,
                backgroundColor:mye.mycols().white,
                margin:4
            }} onClick={()=>{
                if(!authed){
                    setAction(pp.pos)
                    setAuthMe(true)
                }
            }}>
                {pp.pos==0?shIt?<Visibility style={sty} />:<VisibilityOff style={sty} />:
                pp.pos==1?<ContentCopy style={sty} />:<Share style={sty} />}
            </div>
        }
    }

    function PatronLay(prop:{eles:patronEle[],rdy:boolean}){
        const mykey = Date.now()
        return <div style={{
            width:isMobile?'100%':400,
            boxSizing:'border-box',
            padding:20,
            height:500,
            margin:isMobile?10:20,
            backgroundColor:mye.mycols().white,
            borderRadius:10,
        }}>
            <mye.BTv text="Patrons" size={20} color={mye.mycols().primarycol}/>
            <Mgin top={20} />
            {(patred && patrons.length>0)?<div style={{
                overflowY:'scroll'
            }}>
                {prop.eles.map((ele,i)=>{
                    return <PatMini key={mykey+i} ele={ele}/>
                })}
            </div>:<div className="ctr" style={{
                width:'100%',
                height:'100%'
            }}>
                {patred?<mye.Tv text="Nothing Yet" color={mye.mycols().hint} />:<CircularProgress />}
                </div>}
        </div>

        function PatMini(pp:{ele:patronEle}) {
            return <div className="hlc" style={{
                width:'100%',
            }}>
                <div className="ctr" style={{
                    width:50,
                    height:50
                }}>
                    <Business className="icon"/>
                </div>
                <div style={{
                    flex:1,
                }}>
                    <mye.BTv text={getWho(pp.ele.getBiz())+' '+pp.ele.getBiz().substring(1)} size={16}/>
                    <Mgin top={5} />
                    <mye.Tv text={`For Design ${pp.ele.getDesign().substring(1)}`} size={14} color={mye.mycols().hint}/>
                    <Mgin top={3} />
                    <mye.Tv text={pp.ele.getTime()} size={12} color={mye.mycols().hint}/>
                </div>
            </div>
        }
    }

    function ActLay(prop:{eles:actEle[],rdy:boolean}){
        const mykey = Date.now()
        return <div style={{
            width:isMobile?'100%':400,
            boxSizing:'border-box',
            padding:20,
            height:500,
            margin:isMobile?10:20,
            backgroundColor:mye.mycols().white,
            borderRadius:10,
        }}>
            <mye.BTv text="Activities" size={20} color={mye.mycols().primarycol}/>
            <Mgin top={20} />
            {(patred && patrons.length>0)?<div style={{
                overflowY:'scroll'
            }}>
                {prop.eles.map((ele,i)=>{
                    return <ActMini key={mykey+i} ele={ele}/>
                })}
            </div>:<div className="ctr" style={{
                width:'100%',
                height:'100%'
            }}>
                {patred?<mye.Tv text="Nothing Yet" color={mye.mycols().hint} />:<CircularProgress />}
                </div>}
        </div>

        function ActMini(pp:{ele:actEle}) {
            return <div className="hlc" style={{
                width:'100%',
            }}>
                <div className="ctr" style={{
                    width:50,
                    height:50
                }}>
                    <Business className="icon"/>
                </div>
                <div style={{
                    flex:1,
                }}>
                    <mye.BTv text={pp.ele.getTitle()} size={16}/>
                    <Mgin top={5} />
                    <mye.Tv text={pp.ele.getDevInfo()} size={12} color={mye.mycols().hint}/>
                    <Mgin top={3} />
                    <mye.Tv text={pp.ele.getTime()} size={12} color={mye.mycols().hint}/>
                </div>
            </div>
        }
    }

}
