import { DocumentSnapshot, getFirestore, doc as docu } from "firebase/firestore";
import { getData, getFS } from "./firehelpers";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { getRatingThreshold, getTimeStr } from "./general";


export class profileEle{
    doc:DocumentSnapshot;
    constructor(doc:DocumentSnapshot){
        this.doc = doc
    }
    getId(){
        return this.doc.id
    }
    getName(){
        return getData(this.doc,'n')
    }
    
    amAdmin(myuid:string){
        return getData(this.doc,'a') == myuid
    }
    getPhoto(rdy:(url:string)=>void){
        getDownloadURL(ref(getStorage(),`dp/${this.doc.id}`)).then((url)=>{
            rdy(url)
        }).catch((e)=>{
            console.error(e)
        })
    }
    getPoints(){
        return parseInt(getData(this.doc,'pt'))
    }
    getRating(){
        let pt = this.getPoints()
        if(pt===0){
            return 0
        }
        if(pt>getRatingThreshold()){
            pt = getRatingThreshold()
        }
        return ((pt/getRatingThreshold())*5)
    }
    getShortCode(){
        return getData(this.doc,'s')
    }
    getLocId(){
        return getData(this.doc,'l')
    }
    getPricingPlanID(){
        return getData(this.doc,'c')
    }
    getMasterId(){
        return getData(this.doc,'m')
    }
    isVerified(){
        return getData(this.doc,'v') == '1'
    }

    //--- CUSTOM
    isEcommerce(){
        return this.doc.id.startsWith('e')
    }
    isLogistics(){
        return this.doc.id.startsWith('l')
    }
    isDeveloper(){
        return this.doc.id.startsWith('d')
    }
    isDevTeam(){
        return this.doc.id.startsWith('t')
    }
    isPerson(){
        return this.doc.id.startsWith('p')
    }

    isBranch(){
        return getData(this.doc,'m') != '0'
    }

    
}

export class trxEle{
    doc:DocumentSnapshot;
    constructor(doc:DocumentSnapshot){
        this.doc = doc;
    }

    getAmt(){
        return getData(this.doc,'a')
    }
    getFrom(){
        const fuid = getData(this.doc,'w')
        return getWho(fuid)+' '+fuid.substring(1)
    }
    getFor(){
        const fuid = getData(this.doc,'f')
        return getWho(fuid)+' '+fuid.substring(1)
    }
}

export function getWho(fuid:string){
    let rsp = ''
    if(fuid.startsWith('d')){
        rsp = 'Developer'
    }
    if(fuid.startsWith('t')){
        rsp = 'Team'
    }
    if(fuid.startsWith('e')){
        rsp = 'Store'
    }
    if(fuid.startsWith('l')){
        rsp = 'Logistic'
    }
    if(fuid.startsWith('p')){
        rsp = 'Person'
    }
    return rsp
}

export class bootcampEle{
    doc:DocumentSnapshot
    constructor(doc:DocumentSnapshot){
        this.doc = doc;
    }
    getPic(rdy:(url:string)=>void){
        getDownloadURL(ref(getStorage(),`bootcamps/${this.doc.id}`)).then((url)=>{
            rdy(url)
        }).catch((e)=>{
            console.error(e)
        })
    }
    getTitle(){
        return getData(this.doc,'t')
    }
    getDesc(){
        return getData(this.doc,'d')
    }
    getCode(){
        return getData(this.doc,'c')
    }
    getRegForm(){
        return getData(this.doc,'f')
    }
    getLink(){
        return getData(this.doc,'l')
    }
}

/* time-mills = t */
export class blogEle{ //TODO Only ID and time is needed! Use URL preview to get the rest
    doc:DocumentSnapshot;
    constructor(doc:DocumentSnapshot){
        this.doc = doc;
    }
    getId(){
        return this.doc.id
    }
    getTitle(){
        return this.getId().replaceAll('-',' ')
    }
    getDesc(){
        return getData(this.doc,'d')
    }
    getTime(){
        return getTimeStr(parseFloat(getData(this.doc,'t')))
    }
    getBid(){
        return this.doc.ref.parent.id
    }
    isDocs(){
        return this.getBid() == 'docs'
    }
    getLink(){
        if(this.isDocs()){
            return `https://docs.monagree.com/${this.getId()}`
        }else{
            return `https://blog.monagree.com/${this.getBid()}/${this.getId()}`
        }
    }
}

export class getPointEle{
    doc:DocumentSnapshot
    constructor(doc:DocumentSnapshot){
        this.doc = doc
    }
    getTitle(){
        return getData(this.doc,'t')
    }
    getDesc(){
        return getData(this.doc,'d')
    }
    getClaim(){
        return getData(this.doc,'c')
    }
    hasClaims(){
        return this.getClaim().length>0
    }
}

export class designEle{
    doc:DocumentSnapshot
    iUrl:string
    mCats:string[]
    constructor(doc:DocumentSnapshot){
        this.doc = doc
        this.iUrl = ''
        this.mCats = []
    }
    getTitle(){
        return getData(this.doc,'t')
    }
    getDesc(){
        return getData(this.doc,'d')
    }
    getAuthor(){
        return getData(this.doc,'a')
    }
    isPremiuim(){
        return getData(this.doc,'p')==='1'
    }
    isStore(){
        return getData(this.doc,'e')==='1'
    }
    getC1(){
        return getData(this.doc,'c1')
    }
    getC2(){
        return getData(this.doc,'c2')
    }
    getC3s(){
        return this.doc.get('c3') as Array<string>
    }
    getCats(rdy:(catNames:string[])=>void){
        if(this.mCats.length>0){
            rdy(this.mCats)
        }else{
            this.handleCat(()=>{
                rdy(this.mCats)
            },this.getC3s(),0)
        }
    }
    handleCat(rdy:()=>void,catIds:string[],pos:number){
        if(pos==catIds.length){
            rdy()
            return;
        }
        getFS(docu(getFirestore(),`meta/cats/${this.getC1()}/${this.getC2()}/sc/${catIds[pos]}`),(task)=>{
            if(task.isSuccessful()){
                const cn = getData(task.getResult(),'t')
                this.mCats.push(cn)
            }
            const np = pos+1
            this.handleCat(rdy,catIds,np)
        })
    }
    getPic(rdy:(url:string)=>void){
        if(this.iUrl.length>0){
            rdy(this.iUrl)
        }else{
            getDownloadURL(ref(getStorage(),`desg-pic/${this.doc.id}/m`)).then((url)=>{
                this.iUrl = url
                rdy(url)
            }).catch((e)=>{
                console.error(e)
            })
        }
       
    }
    //Photo
}


export class patronEle{
    doc:DocumentSnapshot
    constructor(doc:DocumentSnapshot){
        this.doc = doc
    }
    getDesign(){
        return getData(this.doc,'w')
    }
    getBiz(){
        return getData(this.doc,'b')
    }
    getDev(){
        return getData(this.doc,'d')
    }
    getTime(){
        return getTimeStr(parseFloat(getData(this.doc,'t')))
    }
}

export class actEle{
    doc:DocumentSnapshot
    constructor(doc:DocumentSnapshot){
        this.doc = doc
    }
    getWho(){
        return getData(this.doc,'w')
    }
    getTitle(){
        return `${this.getWho().length==0?'You':this.getWho()} Logged In`
    }
    getDevInfo(){
        return getData(this.doc,'i')
    }
    getTime(){
        return getTimeStr(parseFloat(this.doc.id))
    }
}

export class CommunityEle{
    dp:string
    name:string 
    link:string
    constructor(dp:string, name:string, link:string){
        this.dp = dp
        this.name = name
        this.link = link
    }
}