import React, { useState } from "react";
import CodeEditor from '@uiw/react-textarea-code-editor';
import { MyCols } from "../helper/general";
import { PlayArrow, Stop } from "@mui/icons-material";
import useWindowDimensions from "../helper/dimension";

export default function MonagreeEditor(prop:{codeStat:(stat:boolean)=>void}){
    const [code, setCode] = useState('');
    const [run, setRun] = useState(false)

    return (
        <div style={{
            width:"100%",
            height:'100%'
        }}>
            {run?<RunCode code={code} />:<CodeEditor
                value={code}
                language="html"
                placeholder="<!-- Any Body Can Dev, You've got this!   Use TAB for indentation, Click play below to run -->"
                onChange={(evn) => setCode(evn.target.value)}
                padding={15}
                style={{
                    height:'100%',
                    color:"rgba(0,0,0,0.75)",
                    fontSize: 12,
                    backgroundColor: "#f5f5f5",
                    fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                    borderRadius:20,
                }}
            />}
            <div style={{
                width:50,
                height:50,
                borderRadius:40,
                backgroundColor:MyCols().primarycol,
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                position:"absolute",
                bottom:'30px',
                right:'30px'
            }} onClick={()=>{
                setRun(!run)
                prop.codeStat(!run)
            }}>
                {run?<Stop className="icon" style={{
                    color:MyCols().white
                }}/>:<PlayArrow className="icon" style={{
                    color:MyCols().white
                }}/>}
            </div>
        </div>
    );

}

function RunCode(prop:{code:string}){

    return (
        <iframe srcDoc={prop.code} width={"100%"} height={"100%"} style={{
            border:'none',
            backgroundColor: "#f5f5f5",
            borderRadius:20,
        }}/>
    )

}