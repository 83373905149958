import { Add, Close, Done, Edit, KeyboardArrowDown, Menu, People } from "@mui/icons-material";
import { CircularProgress, Rating } from "@mui/material";
import { FirebaseApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { collection, getFirestore , doc as docu, getDocs, query, where} from "firebase/firestore";
import { getStorage, ref, uploadString } from "firebase/storage";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../helper/dimension";
import { getFS, getUid, setFS, setUid } from "../helper/firehelpers";
import { BoldText, Btn, EditText, ErrorCont, getCT, LrText, Mgin, MyCols, myEles, StripBtn } from "../helper/general";
import Toast from "./toast/toast";
import devicon from "../assets/dev.png"
import MonagreeEditor from "./editor";
import { profileEle } from "../helper/classes";
import imageCompression from "browser-image-compression";
import Bootcamps from "./bootcamps";
import Overview from "./overview";
import Designs from "./designs";
import { mLoc } from "monagree-locs/dist/classes";
import { mCountry, mLga, mState } from "monagree-locs";
import Api from "./api";
import { MonagreeCommunity } from "./community";
import MonDocs from "./documentation";
import MonBlogs from "./blogs";


function useQuery(){
    return new URLSearchParams(useLocation().search);
}

export default function Damain(mainprop:{isNgt:boolean,isApp:boolean,playform:FirebaseApp}){
    const[myKey,setMyKey] = useState(Date.now())
    const[ran, setRan] = useState(false);
    const[rdy, setRdy] = useState(false);
    const qry = useQuery();
    const[tabPos, setTabPos] = useState(0)
    const[isMobile, setIsMobile] = useState(true)
    const[showSideBar, setShowSideBar] = useState(false)
    const[codeStat, setCodeStat] = useState(false)
    //--KYC
    const[kycTeam, setKycTeam] = useState(false)
    const[doKYC, setDoKYC] = useState(false)
    const[haveCreatedTeam, setHaveCreatedTeam] = useState(false)
    const[fn, setFn] = useState('')
    const[ln, setLn] = useState('')
    const[phn, setPhn] = useState('')
    const[iDataUrl, setIdataUrl] = useState('')
    const[profiles, setProfiles] = useState<{
        ele:profileEle,
        iurl:string
    }[]>([])
    const[profilePos,setProfilePos] = useState(0)
    const [country, setCountry] = useState<mLoc>()
    const [state, setState] = useState<mLoc>()
    const [lga, setLga] = useState<mLoc>()

    const dimen = useWindowDimensions()
    const navigate = useNavigate();
    const mye = new myEles(mainprop.isNgt);
    const tabs = ['Overview','Designs','API','Code','Documentation','Bootcamps','Blog','Community']
    const mykey = Date.now()
    

    useEffect(()=>{
        setIsMobile(!dimen.dsk)
        if(mainprop.isNgt){
            document.body.classList.toggle("dark-theme")
        }
        const unsubscribe = getAuth().onAuthStateChanged((user)=>{
            if(user){
                if(!ran){
                    setRan(true);
                    begin()
                }
            }else{
                navigate(`/signin`)
            }
        });
        return ()=>{
            unsubscribe();
          }
    },[])

    function updatePos(pos:number,ele:profileEle, iurl:string){
        setProfiles(prevProfs => {
            const updatedProfs = [...prevProfs];
            updatedProfs[pos] = {
                ele:ele,
                iurl:iurl
            };
            return updatedProfs;
          });
    }

    function begin(){
        //-- Get primary
        setLoad(true)
        setUid('d'+getAuth().currentUser!.uid)
        console.log(getUid())
        getFS(docu(getFirestore(),`prfs/${getUid()}`),(task)=>{
            if(task.isSuccessful()){
                console.log('success')
                setLoad(false)
                if(task.exists()){
                    console.log('exist')
                    let tem:{
                        ele:profileEle,
                        iurl:string
                    }[] = []
                    const ele = new profileEle(task.getResult())
                    tem.push({
                        ele:ele,
                        iurl:''
                    });
                    setProfiles(tem)
                    ele.getPhoto((url)=>{
                        console.log('PHOTO RDY 1')
                        updatePos(0,ele,url)
                    })
                    setRdy(true)
                    //-- GET TEAMS
                    getDocs(query(collection(getFirestore(),`prfs`),where(
                        'pl','array-contains',getUid()
                    ))).then((docs)=>{
                        docs.docs.forEach((doc, index)=>{
                            const ele = new profileEle(doc)
                            tem.push({
                                ele:ele,
                                iurl:''
                            })
                            ele.getPhoto((url)=>{
                                console.log('PHOTO RDY 2')
                                updatePos(index+1,ele,url)
                            })
                            if(ele.amAdmin(getUid())){
                                setHaveCreatedTeam(true)
                            }
                        })
                        setProfiles(tem)
                    }).catch((e)=>{
                        console.error(e)
                    })
                }else{
                    setKycTeam(false)
                    setDoKYC(true)
                }
            }else{
                setLoad(false)
                setError(true)
            }
        })
    }


    const[load, setLoad]=useState(false)
    const[error, setError]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    function toast(msg:string, action:number,delay?:number){
      var _delay = delay || 5000
      setToastMeta({
          action: action,
          msg: msg,
          visible:true,
          invoked: Date.now()
      })
      clearTimeout(timy.timer)
      setTimy({
          timer:setTimeout(()=>{
              if(Date.now()-toastMeta.invoked > 4000){
                  setToastMeta({
                      action:2,
                      msg:"",
                      visible:false,
                      invoked: 0
                  })
              }
          },_delay)
      });
    }

    return (<div style={{
        backgroundColor:'lavender',
        width:dimen.width,
        height:dimen.height
    }}  data-theme={mainprop.isNgt?'dark':'light'}>
        <ErrorCont isNgt={mainprop.isNgt} visible={error} retry={()=>{

        }}/>
        <div className="prgcont" style={{display:load?"flex":"none"}}>
            <CircularProgress/>
        </div>
        <Toast isNgt={mainprop.isNgt} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                setToastMeta({
                    action:2,
                    msg:"",
                    visible:false,
                    invoked:0,
                })
            }} />
        {rdy?<div style={{
            width:'100%',
            display:"flex",
            height:dimen.height
        }}>
            <div style={{
                display:isMobile?"none":"unset"
            }}>
                <SideBar key={tabPos *2.2} pos={tabPos}/>
            </div>
            <div className="vlc" style={{
                flex:1,
                height:'100%',
                padding:20,
                boxSizing:'border-box',
            }}>
                <div style={{
                    width:'100%'
                }}>
                    <LrText left={<mye.BTv font={'Pacifico'} text={codeStat?'Code Output':tabs[tabPos]} color={MyCols().primarycol} size={22}/>} right={isMobile?<Menu className="icon" onClick={()=>{
                    setShowSideBar(!showSideBar);
                     }} />:<Mgin top={0} />} />
                </div>
                <Mgin top={20}/>
                <div style={{
                    flex:1,
                    width:'100%',
                    overflow:'scroll'
                }}>
                    {
                        tabPos===0?<Overview isNgt={mainprop.isNgt} playform={mainprop.playform} profile={profiles[profilePos].ele} move={(pos)=>{
                            setTabPos(pos)
                        }} />:
                        tabPos===1?<Designs isNgt={mainprop.isNgt} move={(pos)=>{
                            setTabPos(pos)
                        }} profile={profiles[profilePos].ele}  />:
                        tabPos==2?<Api isNgt={mainprop.isNgt} profile={profiles[profilePos].ele} />:
                        tabPos==4?<MonDocs isNgt={mainprop.isNgt}  />:
                        tabPos===5?<Bootcamps isNgt={mainprop.isNgt}/>:tabPos===3?<MonagreeEditor codeStat={(stat)=>{
                            setCodeStat(stat);
                            toast(stat?'Executing Code':'Code Execution Aborted',1);
                        }} />:
                        tabPos==6?<MonBlogs isNgt={mainprop.isNgt} />:
                        tabPos==7?<MonagreeCommunity isNgt={mainprop.isNgt}  />:<GoDoCourse />
                    }
                </div>
            </div>
            <div style={{
                display:isMobile&&showSideBar?'unset':'none',
                position:'fixed',
                top:0,
                right:0,
                width:dimen.width,
                height:dimen.height
            }}>
                <SideBar key={tabPos *2.2}  pos={tabPos} />
            </div>
        </div>:<div className="ctr" style={{
            width:'100%',
            height:'100%',
        }}>
            <CircularProgress />
            </div>}
        <div className="ctr" style={{
            display:doKYC?'flex':'none',
            backgroundColor:'rgba(0,0,0,0.1)',
            position:'absolute',
            top:0,
            left:0,
            width:'100%',
            height:'100%',
            padding:isMobile?20:40,
            boxSizing:'border-box',
        }}>
            <div style={{
                width:isMobile?'100%':400,
                overflowY:'scroll',
                backgroundColor:mye.mycols().white,
                borderRadius:20,
                padding:20,
                boxSizing:'border-box',
            }}>
                <div>
                    <LrText left={<mye.BTv color={mye.mycols().primarycol} size={18} text={kycTeam?'New Team':"Complete Profile"} />} 
                    right={<Close className="icon" style={{
                        display:kycTeam?undefined:'none'
                    }} onClick={()=>{
                        setDoKYC(false);
                         }} />} />
                    <Mgin top={5}/>
                    <mye.Tv size={12} text={kycTeam?'Team profile is separate from personal. You can add members and manage their access later':"Please complete your profile"} />
                    <Mgin top={20}/>
                    <div className="vlc">
                        <div style={{
                            width:200,
                            height:200,
                            borderRadius:'50%',
                            backgroundColor: mye.mycols().btnstrip,
                            position:'relative'
                        }} onClick={()=>{
                            const input = document.createElement('input');
                            input.type = 'file';
                            input.accept = 'image/*';
                            input.onchange = (e) => {
                            const fileInput = e.target as HTMLInputElement; // Cast to HTMLInputElement
                            const file = fileInput.files?.[0]; // Use files property

                            if (file) {
                                const options = {
                                    maxSizeMB: 0.5,
                                    maxWidthOrHeight: 560,
                                    useWebWorker: true,
                                }
                                imageCompression(file, options).then((compressedFile)=>{
                                    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
                                    const reader = new FileReader();
                                    reader.onload = (event) => {
                                        setIdataUrl(event!.target!.result as string);
                                    };
                                    reader.readAsDataURL(compressedFile);
                                }).catch((e)=>{
                                    console.error(e)
                                })
                            }
                            };
                            input.click();
                        }}>
                            <img src={iDataUrl} alt="." style={{
                                width:'100%',
                                height:'100%',
                                borderRadius:'50%',
                                objectFit:'cover',
                                content:'none',
                                border:'none'
                            }}/>
                            <div className="ctr" style={{
                                position:'absolute',
                                bottom:0,
                                right:0,
                                backgroundColor:mye.mycols().primarycol,
                                borderRadius:'50%',
                                width:40,
                                height:40
                            }}>
                                <Edit style={{
                                    fontSize:20,
                                    color:mye.mycols().white
                                }} />
                            </div>
                        </div>
                        <Mgin top={20} />
                        <EditText hint={kycTeam?'Team Name':"First Name"} isNgt={mainprop.isNgt} min={3} max={20} value={fn} noSpace={!kycTeam} recv={(val)=>{
                            setFn(val.trim())
                        }} />
                        <div style={{
                            display:kycTeam?'none':undefined,
                            width:'100%'
                        }}>
                            <Mgin top={15}/>
                            <EditText hint="Last Name" isNgt={mainprop.isNgt} min={3} max={20} value={ln} noSpace recv={(val)=>{
                                setLn(val.trim())
                            }} />
                        </div>
                        <Mgin top={15}/>
                        <EditText value={phn} isNgt={mainprop.isNgt} hint="Phone Number" digi min={11} max={11} recv={(val)=>{
                            setPhn(val.trim())
                        }}/>
                        <Mgin top={10} />
                        <mye.BTv text="Where are you based ?" size={16} />
                        <Mgin top={10} />
                        <select id="dropdown" name="dropdown" value={country?.getId() || ''} onChange={(e)=>{
                            const ele = mCountry.getCountryByCode(e.target.value)
                            setCountry(ele)
                            setState(undefined)
                            setLga(undefined)
                        }}>
                            <option value="">Choose Country</option>
                            {
                                mCountry.getAllCountries().map((ele, index)=>{
                                    return <option key={myKey+index+10000} value={ele.getId()}>{ele.getName()}</option>
                                })
                            }
                        </select>
                        <Mgin top={20} />
                        <select id="dropdown" name="dropdown" value={state?.getId()||''} onChange={(e)=>{
                            if(country?.getId() == 'NG'){
                                const ele = mState.getStateByCode(country!.getId(),e.target.value)
                                setState(ele)
                                setLga(undefined)
                            }
                            
                        }} style={{
                            display:country?.getId()!='NG'?'none':undefined
                        }}>
                            <option value="">Choose One</option>
                            {
                                country?country?.getId() == 'NG'?mState.getStatesByCountry(country!.getId(),true).map((ele, index)=>{
                                    return <option key={myKey+index+1000} value={ele.getId()}>{ele.getName()}</option>
                                }):undefined:<option value="option1">Choose Country First</option>
                            }
                        </select>
                        <Mgin top={20} />
                        <select id="dropdown" name="dropdown" value={lga?.getId()||''} onChange={(e)=>{
                            if(country?.getId() == 'NG' && state){
                                const ele = mLga.getLgaByCode(country!.getId(),state!.getId(),e.target.value)
                                setLga(ele)
                            }
                        }} style={{
                            display:country?.getId()!='NG'?'none':undefined
                        }}>
                            <option value="">Choose One</option>
                            {
                                (country&& state)?country?.getId() == 'NG'?mLga.getLgasByState(country!.getId(),state!.getId(),true).map((ele, index)=>{
                                    return <option key={myKey+index+100} value={ele.getId()}>{ele.getName()}</option>
                                }):undefined:<option value="option1">Choose Country & State First</option>
                            }
                        </select>
                        <div style={{
                            display:(country && country?.getId()!='NG')?undefined:'none'
                        }}>
                            <mye.BTv text={`Sorry, Monagree is not yet available in ${country?.getName()}`} color={mye.mycols().red} size={14}/>
                        </div>
                    </div>
                    <Mgin top={20} />
                    <Btn txt={load?'JUST A SEC':"PROCEED"} onClick={()=>{
                        if(!country || !state || !lga){
                            toast('Please choose location',0)
                            return
                        }
                        if(country.getId()!='NG'){
                            toast('Monagree is only available in Nigeria for Now',2)
                            return;
                        }
                        if(load){
                            toast('Just a sec',2)
                            return;
                        }
                        if(iDataUrl.length==0){
                            toast('Please include photo',2)
                            return;
                        }
                        if(fn.length==0 || (!kycTeam && ln.length ==0)){
                            toast('Please include name',2)
                            return;
                        }
                        if(phn.length<11){
                            toast('Please include phone number',2)
                            return;
                        }
                        setLoad(true)
                        const devid = 'd'+getAuth().currentUser!.uid
                        const duid = kycTeam?'t'+getCT():devid
                        setFS(docu(getFirestore(),`prfs/${duid}`),kycTeam?{
                            l:country.getId()+state.getId()+lga.getId(),
                            n:fn,
                            p:phn,
                            a: devid,
                            pl:[devid],
                            pt:0, //Points
                            v:'0'
                        }:{
                            l:country.getId()+state.getId()+lga.getId(),
                            n:fn+' '+ln,
                            p:phn,
                            pt:0,
                            v:'0'
                        },(task)=>{
                            if(task._success){
                                uploadString(ref(getStorage(),`dp/${duid}`),iDataUrl,'data_url').then((snp)=>{
                                    setLoad(false)
                                    setDoKYC(false)
                                    setRdy(false)
                                    if(kycTeam){
                                        setProfilePos(profilePos+1)
                                    }
                                    begin()
                                }).catch((e)=>{
                                    setLoad(false)
                                    console.log(e)
                                    toast('An error occured. Please try again',0)  
                                })
                            }else{
                                setLoad(false)
                                toast('An error occured. Please try again',0)
                            }
                        })
                    }} />
                </div>
            </div>
        </div>
    </div>);

    function GoDoCourse(){
        return <div className="ctr" style={{
            width:'100%',
            height:'100%'
        }}>
            <img alt="devicon" src={devicon} style={{
                height:70
            }}/>
            <Mgin top={20}/>
            <mye.BTv text="Complete Course" size={16} color={mye.mycols().hint}  />
            <Mgin top={10}/>
            <mye.Tv text="This page is locked till you complete the ABCD bootcamp" center/>
            <Mgin top={10}/>
            <div style={{
                display:"flex",
            }}>
                <StripBtn width={150} txt="Code Editor" onClick={()=>{
                    setTabPos(3);
                }}/>
                <Mgin right={10}/>
                <StripBtn width={150} txt="Complete Bootcamp" onClick={()=>{
                    setTabPos(5);
                }}/>
            </div>
            <Mgin top={10}/>
            <StripBtn width={150} txt="Join Community" onClick={()=>{
                window.open('https://chat.whatsapp.com/KYn7sfdqHT15bwiSe9W7t6','_blank','noreferrer')
            }}/>
        </div>
    }

    function ProfilePicker(){
        const [showList, setShowList] = useState(false)

        return <div style={{
            backgroundColor:showList?mye.mycols().btnstrip5:mye.mycols().transparent,
            borderRadius:10,
            padding:5,
            marginRight:5
        }}>
            {
                profiles.map((prof,index)=>{
                    return <ProfLay mye={mye} state={index==profilePos?showList?1:2:showList?3:0} key={mykey+100+index} prof={prof} ocl={()=>{
                        if(index == profilePos){
                            setShowList(!showList)
                        }else{
                            setShowSideBar(false)
                            setRdy(false)
                            setProfilePos(index)
                            setUid(prof.ele.doc.id)
                            setTimeout(()=>{
                                setRdy(true)
                            },500)
                        }
                    }} />
                })
            }
            <div className="hlc"onClick={()=>{
                setKycTeam(true)
                setIdataUrl('')
                setFn('')
                setDoKYC(true)
            }} style={{
                display:(showList&& !haveCreatedTeam)?'flex':'none',
                padding:5
            }} >
                <div className="ctr" style={{
                    width:37,
                    height:37,
                    borderRadius:'50%',
                    marginRight:7,
                    backgroundColor:mye.mycols().white
                }} >
                    <People className="icon" />
                </div>
                <div style={{
                    flex:1
                }}>
                    <mye.BTv color={mye.mycols().hint} size={14} text={'Create Team'} maxLines={1} />
                    <Mgin top={3}/>
                    <mye.Tv text="Build with others" />
                </div>
                <Add className="icon" />
            </div>
        </div>

    }

    function SideBar(prop:{pos:number}){
        

        return (
            <div style={{
                width: isMobile?dimen.width-40:250,
                height: dimen.height,
                overflowY:'scroll',
                backgroundColor:'rgb(222, 222, 245)',
                padding:isMobile?'0px 20px':'0px 0px 0px 20px',
                display:"flex",
                flexDirection:'column',
            }}>
                <div className="ctr" style={{
                    alignSelf:'flex-end',
                    width:50,
                    height:50,
                    display:isMobile?'flex':'none'
                }} onClick={()=>{
                    setShowSideBar(false)
                }}>
                    <Close className="icon" />
                </div>
                <div className="ctr" style={{
                    width:"100%",
                    height:200
                }}>
                    <img alt="Dev Icon" src={devicon} style={{
                        width:65,
                        height:65,
                        borderRadius:40
                    }} />
                    <Mgin top={10}/>
                    <mye.BTv text="monagree for devs" size={15} color={mye.mycols().hint} />
                </div>
                <ProfilePicker />
                <Mgin top={40}/>
                <TabTopic txt="Build" />
                <TabBtn index={0} />
                <TabBtn index={1} />
                <TabBtn index={2} />
                <TabBtn index={3} />
                <TabTopic txt="Learn" />
                <TabBtn index={4} />
                <TabBtn index={5} />
                <TabTopic txt="Engage" />
                <TabBtn index={6} />
                <TabBtn index={7} />
                <Mgin top={100}/>
            </div>
        )

        function TabTopic(prop:{txt:string}){
            return <div>
                <Mgin top={20} />
                <mye.BTv text={prop.txt} size={14} />
            </div>
        }

        function TabBtn(sprop:{index:number}){
            if(sprop.index === prop.pos){
                return <div key={mykey+1+sprop.index}>
                    <Mgin top={10}/>
                    <StripBtn solid  tabbish txt={tabs[sprop.index]}/>
                </div>
            }else{
                return <div key={mykey+1+sprop.index}>
                    <Mgin top={10}/>
                    <StripBtn tabbish txt={tabs[sprop.index]} onClick={()=>{
                        setTabPos(sprop.index)
                        setShowSideBar(false)
                        setCodeStat(false)
                     }} />
                </div>
            }
        }

    }
            
    function MiniWallet(prop:{amt:number}){

        return (
            <div className="ctr" style={{
                width:'230px',
                backgroundColor: mye.mycols().white,
                padding: '12px 20px',
                borderRadius: 10,
            }}>
                <BoldText isNgt={mainprop.isNgt} size={18} text={`NGN ${prop.amt}`} />
                <Mgin top={5}/>
                <div style={{
                    display:'flex',
                    width:'100%'
                }}>
                    <div style={{
                        flex:1
                    }}>
                        <StripBtn smallie txt="Transactions" />
                    </div>
                    <Mgin right={10} />
                    <div style={{
                        flex:1
                    }}>
                        <Btn smallie txt="Transfer" />
                    </div>
                </div>
            </div>
        )

    }

}

export function ProfLay(prop:{mye:myEles,prof:{ele:profileEle,iurl:string},state:number, ocl:()=>void}){

    return <div className="hlc"onClick={prop.ocl} style={{
        display:prop.state==0?'none':'flex',
        marginBottom:5,
        padding:5,
        backgroundColor:prop.mye.mycols().btnstrip5,
        borderRadius:10
    }} >
        <img style={{
            width:37,
            height:37,
            borderRadius:'50%',
            marginRight:7,
            backgroundColor:prop.mye.mycols().white,
            objectFit:'cover'
        }} alt="" src={prop.prof.iurl} />
        <div style={{
            flex:1
        }}>
            <prop.mye.BTv color={prop.mye.mycols().hint} size={14} text={prop.prof.ele.getName()} maxLines={1} />
            <Mgin top={3}/>
            <Rating style={{
                color:prop.mye.mycols().primarycol
            }} color={prop.mye.mycols().primarycol} readOnly value={prop.prof.ele.getRating()} precision={0.5} getLabelText={(val)=> "..."} size="small"/>
        </div>
        <div style={{
            alignSelf:'flex-start'
        }}>
            {prop.state==1?<Done className="icon" style={{
                color:prop.mye.mycols().imghint,
                fontSize:18
            }} />:prop.state==2?<KeyboardArrowDown className="icon" style={{
                color:prop.mye.mycols().imghint,
                fontSize:20
            }} />:<Mgin />}
        </div>
    </div>

}