
import { Close, ListAlt, Menu } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { FirebaseApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { collection, getFirestore} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../helper/dimension";
import { getQuery } from "../helper/firehelpers";
import { ErrorCont, Mgin, myEles } from "../helper/general";
import Toast from "./toast/toast";
import { blogEle } from "../helper/classes";


function useQuery(){
    return new URLSearchParams(useLocation().search);
}

export default function MonBlogs(mainprop:{isNgt:boolean}){
    const dimen = useWindowDimensions()
    const navigate = useNavigate()
    const mye = new myEles(mainprop.isNgt)
    const[myKey,setMyKey] = useState(Date.now())
    const[ran, setRan] = useState(false);
    const[rdy, setRdy] = useState(false);
    const qry = useQuery();
    const[tabPos, setTabPos] = useState(0)
    const[isMobile, setIsMobile] = useState(true)
    const[showSideBar, setShowSideBar] = useState(false)
    const[blogs, setBlogs] = useState<blogEle[]>([])
    

    useEffect(()=>{
        setIsMobile(!dimen.dsk)
        if(mainprop.isNgt){
            document.body.classList.toggle("dark-theme")
        }
        const unsubscribe = getAuth().onAuthStateChanged((user)=>{
            if(user){
                if(!ran){
                    setRan(true);
                    begin()
                }
            }else{
                navigate(`/signin`)
            }
        });
        return ()=>{
            unsubscribe();
          }
    },[])

    

    function begin(){
        setRdy(false)
        setError(false)
        getQuery(collection(getFirestore(),'meta/blogs/dev'),(task)=>{
            if(task.isSuccessful()){
                let tem:blogEle[] = []
                task.getResult().docs.forEach((doc)=>{
                    tem.push(new blogEle(doc))
                })
                setBlogs(tem)
                setRdy(true)
            }else{
                setError(true)
            }
        })
    }


    const[load, setLoad]=useState(false)
    const[error, setError]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    function toast(msg:string, action:number,delay?:number){
      var _delay = delay || 5000
      setToastMeta({
          action: action,
          msg: msg,
          visible:true,
          invoked: Date.now()
      })
      clearTimeout(timy.timer)
      setTimy({
          timer:setTimeout(()=>{
              if(Date.now()-toastMeta.invoked > 4000){
                  setToastMeta({
                      action:2,
                      msg:"",
                      visible:false,
                      invoked: 0
                  })
              }
          },_delay)
      });
    }

    return (<div style={{
        backgroundColor:'lavender',
        width:'100%',
        height:'100%'
    }}  data-theme={mainprop.isNgt?'dark':'light'}>
        <ErrorCont isNgt={mainprop.isNgt} visible={error} retry={()=>{

        }}/>
        <div className="prgcont" style={{display:load?"flex":"none"}}>
            <CircularProgress/>
        </div>
        <Toast isNgt={mainprop.isNgt} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                setToastMeta({
                    action:2,
                    msg:"",
                    visible:false,
                    invoked:0,
                })
            }} />
        {isMobile?<div className="vlc" style={{
            width:'100%'
        }}>
            <div id="clk" className="hlc" style={{
                alignSelf:'flex-end',
                padding:10,
                borderRadius:50,
                backgroundColor: mye.mycol.primarycol
            }} onClick={()=>{
                setShowSideBar(!showSideBar)
            }}>
                {showSideBar?<Close style={{
                    fontSize:20,
                    color: mye.mycol.white
                }} />:<Menu style={{
                    fontSize:20,
                    color: mye.mycol.white
                }} />}
                <Mgin right={5} />
                <mye.BTv text="Pages" color={mye.mycol.white} size={14} />
            </div>
        </div>:<div></div>}
        {rdy?<div style={{
            width:'100%',
            display:"flex",
            height:'100%'
        }}>
            <div style={{
                display:isMobile?"none":"unset"
            }}>
                <SideBar key={tabPos *2.2}/>
            </div>
            <div style={{
                flex:1,
                padding:20,
                boxSizing:'border-box',
                overflow:'scroll',
                height: '100%'
            }}>
                {blogs.length>0?<iframe key={tabPos+.3256721}
                    title={blogs[tabPos].getTitle()}
                    width="100%"
                    height="100%"
                    src={blogs[tabPos].getLink()}
                    frameBorder="0"
                />:<div className="ctr" style={{
                    width:'100%',
                    height: '100%'
                }}>
                    <mye.Tv text="Nothing Yet" />
                    </div>}
            </div>
            <div style={{
                display:isMobile&&showSideBar?'unset':'none',
                position:'fixed',
                top:140,
                right:0,
                width:'100%',
                height:'100%'
            }}>
                <SideBar key={tabPos *2.2} />
            </div>
        </div>:<div className="ctr" style={{
            width:'100%',
            height:'100%',
        }}>
            <CircularProgress />
            </div>}
    </div>);

    function SideBar(){
        

        return (
            <div style={{
                width: isMobile?'100%':250,
                height: '100%',
                overflowY:'scroll',
                backgroundColor:'rgb(222, 222, 245)',
                padding:10,
                boxSizing:'border-box',
                display:"flex",
                flexDirection:'column',
            }}>
                <div className="ctr" style={{
                    alignSelf:'flex-end',
                    width:50,
                    height:50,
                    display:isMobile?'flex':'none'
                }} onClick={()=>{
                    setShowSideBar(false)
                }}>
                    <Close className="icon" />
                </div>
                <div className="ctr" style={{
                    width:"100%",
                    height:200
                }}>
                    <ListAlt style={{
                        fontSize:30,
                        color: mye.mycol.primarycol
                    }} />
                    <Mgin top={10}/>
                    <mye.BTv text="Dev Blog" size={16} color={mye.mycol.hint} />
                </div>
                <Mgin top={10}/>
                {
                    blogs.map((bg, i)=>{
                        return <TabBtn index={i} key={myKey+i+0.7671} />
                    })
                }
            </div>
        )

        function TabBtn(sprop:{index:number}){
            return <div style={{
                borderRadius:10,
                width: '100%',
                boxSizing:'border-box',
                padding:10,
                backgroundColor: sprop.index === tabPos? mye.mycol.primarycol: mye.mycol.btnstrip
            }} onClick={()=>{
                setTabPos(sprop.index)
            }}>
                <mye.BTv text={blogs[sprop.index].getTitle()} size={14} color={sprop.index === tabPos? mye.mycol.white: mye.mycol.black}/>
                <Mgin top={3}/>
                <mye.Tv text={blogs[sprop.index].getDesc()} size={12} color={sprop.index === tabPos? mye.mycol.white: mye.mycol.hint} maxLines={2}/>
            </div>
        }

    }
}
