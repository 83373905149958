/* eslint-disable eqeqeq */
import { useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import {useNavigate, useLocation, useParams} from "react-router-dom"
import gcon from "../assets/google.png"
import { CircularProgress, Rating } from "@mui/material";
import useWindowDimensions from "../helper/dimension";
import Toast from "./toast/toast";
import { BoldText, Btn, CloseBtn, EditText, HeadText, isEmlValid, LrText, Mgin, MyCols, myEles, StripBtn, StripBtnImg, TextBox } from "../helper/general";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFS, setFS } from "../helper/firehelpers";
import { getFirestore, doc as docu, getDocs, query, collection, where } from "firebase/firestore";
import { profileEle } from "../helper/classes";
import { ProfLay } from "./damain";

function useQuery(){
    return new URLSearchParams(useLocation().search);
}

export default function AuthMe(mainprop:{isNgt:boolean,isApp:boolean}){
    const[ran, setRan] = useState(false);
    const[ok, setOk] = useState(2);
    const mykey = Date.now()
    const qry = useQuery();

    const navigate = useNavigate();
    const dimen = useWindowDimensions()
    const mye = new myEles(mainprop.isNgt)

    //CLI man
    const mtk = useParams().mtk || ''
    const aid = useParams().aid || ''
    const[prf, setPrf] = useState<profileEle>()
    const[profiles, setProfiles] = useState<{
        ele:profileEle,
        iurl:string
    }[]>([])

    useEffect(()=>{
        if(mainprop.isNgt){
            document.body.classList.toggle("dark-theme")
        }
        const unsubscribe = getAuth().onAuthStateChanged((user)=>{
            if(user){
                if(!ran){
                    setRan(true);
                    getProfiles()
                }
            }else{
                navigate(`/signin?rdr=auth/${aid}/${mtk}`)
            }
        });
        return ()=>{
            unsubscribe();
          }
        
    }, [])

    function updatePos(pos:number,ele:profileEle, iurl:string){
        setProfiles(prevProfs => {
            const updatedProfs = [...prevProfs];
            updatedProfs[pos] = {
                ele:ele,
                iurl:iurl
            };
            return updatedProfs;
          });
    }

    function getProfiles(){
        const dev_uid = 'd'+getAuth().currentUser!.uid 
        getFS(docu(getFirestore(),`prfs/${dev_uid}`),(task)=>{
            if(task.isSuccessful()){
                if(task.exists()){
                    let tem:{
                        ele:profileEle,
                        iurl:string
                    }[] = []
                    const ele = new profileEle(task.getResult())
                    tem.push({
                        ele:ele,
                        iurl:''
                    });
                    setProfiles(tem)
                    ele.getPhoto((url)=>{
                        console.log('PHOTO RDY 1')
                        updatePos(0,ele,url)
                    })
                    iamrdy()
                    //-- GET TEAMS
                    getDocs(query(collection(getFirestore(),`prfs`),where(
                        'm','array-contains',dev_uid
                    ))).then((docs)=>{
                        docs.docs.forEach((doc, index)=>{
                            const ele = new profileEle(doc)
                            tem.push({
                                ele:ele,
                                iurl:''
                            })
                            ele.getPhoto((url)=>{
                                console.log('PHOTO RDY 2')
                                updatePos(index+1,ele,url)
                            })
                        })
                        setProfiles(tem)
                        iamrdy()
                    }).catch((e)=>{
                        setOk(0)
                    })
                }else{
                    iamrdy()
                }
            }else{
                setOk(0)
            }
        })
    }

    function iamrdy(){
        setOk((mtk.length!==0&&aid.length!==0)?1:0)
    }

    function autorize(){
        if(!prf){
            toast('Please choose profile to link',0)
            return
        }
        setLoad(true)
        setFS(docu(getFirestore(),`mtks/${aid+mtk}`),{
            v:prf.getId(),
            s:prf.getShortCode()
        },(task)=>{
            setLoad(false)
            if(task._success){
                setOk(3)
            }else{
                setOk(0)
            }
        })
    }


    const[load, setLoad]=useState(false)
    const[error, setError]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    function toast(msg:string, action:number,delay?:number){
      var _delay = delay || 5000
      setToastMeta({
          action: action,
          msg: msg,
          visible:true,
          invoked: Date.now()
      })
      clearTimeout(timy.timer)
      setTimy({
          timer:setTimeout(()=>{
              if(Date.now()-toastMeta.invoked > 4000){
                  setToastMeta({
                      action:2,
                      msg:"",
                      visible:false,
                      invoked: 0
                  })
              }
          },_delay)
      });
    }


    
    return (
        <div  data-theme={mainprop.isNgt?'dark':'light'} style={{display:"flex",flexDirection:"column", alignItems:"center",backgroundColor:MyCols(mainprop.isNgt).bkg,
         height:dimen.height,width: dimen.width}}>
            <div className="prgcont" style={{display:load?"flex":"none"}}>
                <CircularProgress/>
            </div>
            <Toast isNgt={mainprop.isNgt} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                    setToastMeta({
                        action:2,
                        msg:"",
                        visible:false,
                        invoked:0,
                    })
                }} />
            <div className="ctr" style={{
                width:'100%',
                height:'100%'
                }}>
                <div className="vlc" style={{
                    width:dimen.dsk?500:250,
                    boxSizing:'border-box',
                    borderRadius:10,
                    backgroundColor:mye.mycols().black,
                    padding:20
                }}>
                    <mye.HTv text={ok==1?"Authorization":ok==2?'Just a sec':ok==3?'Success':ok==4?'Cancelled':'Opps, Error'} color={mye.mycols().white} />
                    <Mgin top={20} />
                    <mye.Tv text={ok==1?'Choose a profile to authorize this app/service to use Monagree on your behalf. This grants all priviledges':
                ok==2?'Hold on please':ok==3?'You can return to the app/service now':ok==4?'Permission was not granted. Refresh the page if you change your mind'
                :'Either this link is broken, network issues or something else'} color={mye.mycols().white} />
                <div style={{
                    width:'100%',
                    display:ok==1?undefined:'none'
                }}>
                    <Mgin top={20} />
                    {profiles.length==0?<div className="ctr" style={{
                        width:'100%',
                        height:100,
                        backgroundColor:mye.mycols().bkg
                    }}>
                        <mye.BTv text="NO PROFILES" size={16} />
                        <Mgin top={5} />
                        <mye.Tv text="have you created one (visit dashboard)" />
                    </div>:profiles.map((prof, index)=>{
                        return <ProfLay prof={prof} key={mykey+index}  />
                    })}
                    <Mgin top={20} />
                    <Mbtn txt="Yes, Authorize" ocl={()=>{
                        autorize()
                    }}  />
                    <Mgin top={10} />
                    <Mbtn txt="No" ocl={()=>{
                        setOk(4)
                    }} strip />
                </div>
                </div>
            </div>
        </div>
    )

    function Mbtn(prop:{txt:string,ocl:()=>void,strip?:boolean}){
        const mye = new myEles(!mainprop.isNgt)
        return <div className="ctr" style={{
            width:'100%',
            height:45,
            borderRadius:4,
            backgroundColor:prop.strip?mye.mycols().btnstrip:mye.mycols().primarycol
        }} onClick={prop.ocl}>
            <mye.Tv text={prop.txt} color={prop.strip?mye.mycols().primarycol:mye.mycols().white} />
        </div>
    }

    function ProfLay(prop:{prof:{ele:profileEle,iurl:string}}){
        const mye = new myEles(true)

        return <div className="hlc"onClick={()=>{
            setPrf(prop.prof.ele)
        }} style={{
            marginBottom:5,
            padding:5,
            backgroundColor:mye.mycols().btnstrip5,
            borderRadius:10
        }} >
            <img style={{
                width:37,
                height:37,
                borderRadius:'50%',
                marginRight:7,
                backgroundColor:mye.mycols().white,
                objectFit:'cover'
            }} alt="" src={prop.prof.iurl} />
            <div style={{
                flex:1
            }}>
                <mye.BTv color={mye.mycols().hint} size={14} text={prop.prof.ele.getName()} maxLines={1} />
                <Mgin top={3}/>
                <Rating style={{
                    color:mye.mycols().primarycol
                }} color={mye.mycols().primarycol} readOnly value={prop.prof.ele.getRating()} precision={0.5} getLabelText={(val)=> "..."} size="small"/>
            </div>
            <div>
            <input
                type="radio"
                color={mye.mycol.primarycol}
                value={prop.prof.ele.getId()}
                checked={prop.prof.ele.getId() === prf?.getId()}
                onChange={()=>{
                    setPrf(prop.prof.ele)
                }}
                />
            </div>
        </div>

    }

}

