import { CommunityEle } from "../helper/classes";
import useWindowDimensions from "../helper/dimension";
import { Mgin, goUrl, myEles } from "../helper/general";


export function MonagreeCommunity(mainprop:{isNgt:boolean}){
    const myKey = Date.now()
    const dimen = useWindowDimensions()
    const mye = new myEles(mainprop.isNgt)

    const communities:CommunityEle[] = [
        new CommunityEle('https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1200px-WhatsApp.svg.png','WhatsApp',
        'https://chat.whatsapp.com/I07YCC9AfNs9ndnXkFvb3s')
    ]

    return <div style={{
        width:'100%',
    }}>
        <mye.BTv text="Join a Community" size={18} />
        <Mgin top={5}/>
        <mye.Tv text="Please adhere to all rules" />
        <Mgin top={20}/>
        <div className="flexi">
            {
                communities.map((com,i)=>{
                    return <Commy bg="black" tcol="green" ele={com} key={myKey+i+0.321} />
                })
            }
        </div>
        
    </div>

function Commy(prop:{ele:CommunityEle,bg:string, tcol:string}){
    return <div id="clk" className="hlc" style={{
        padding:10,
        backgroundColor:prop.bg,
        borderRadius:10,
        margin:10
    }} onClick={()=>{
        goUrl(prop.ele.link)
    }}>
        <img src={prop.ele.dp} height={30} alt={prop.ele.name} style={{
            backgroundColor: mye.mycols().white,
            borderRadius:50
        }}/>
        <Mgin right={10} />
        <mye.BTv text={prop.ele.name} size={18} color={prop.tcol} />
    </div>
}

}