import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../helper/dimension";
import Toast from "./toast/toast";
import { Btn, ErrorCont, LrText, Mgin, StripBtn, Tablet, goUrl, myEles } from "../helper/general";
import { memo, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { blogEle, getPointEle, profileEle, trxEle } from "../helper/classes";
import { getData, getFS, getQuery, getUid } from "../helper/firehelpers";
import { collection, doc as docu, getDocs, getFirestore, limit, orderBy, query, updateDoc, where } from "firebase/firestore";
import { FirebaseApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { fncRes } from "./signin";
import { ArrowRight, Close } from "@mui/icons-material";
import { getAuth } from "firebase/auth";


export default function Overview(mainprop:{isNgt:boolean,profile:profileEle,playform:FirebaseApp, move:(pos:number)=>void}){
    const [ran, setRan] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const dimen = useWindowDimensions()
    const navigate = useNavigate();
    const mye = new myEles(mainprop.isNgt);
    const mykey = Date.now()

    useEffect(()=>{
        if(!ran){
            setRan(true)
            setIsMobile(!dimen.dsk)
        }
    },[])



    const[load, setLoad]=useState(false)
    const[error, setError]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    function toast(msg:string, action:number,delay?:number){
      var _delay = delay || 5000
      setToastMeta({
          action: action,
          msg: msg,
          visible:true,
          invoked: Date.now()
      })
      clearTimeout(timy.timer)
      setTimy({
          timer:setTimeout(()=>{
              if(Date.now()-toastMeta.invoked > 4000){
                  setToastMeta({
                      action:2,
                      msg:"",
                      visible:false,
                      invoked: 0
                  })
              }
          },_delay)
      });
    }

    return (<div style={{
        backgroundColor:'lavender',
        width:'100%',
        height:'100%'
    }}  data-theme={mainprop.isNgt?'dark':'light'}>
        <ErrorCont isNgt={mainprop.isNgt} visible={error} retry={()=>{

        }}/>
        <div className="prgcont" style={{display:load?"flex":"none"}}>
            <CircularProgress/>
        </div>
        <Toast isNgt={mainprop.isNgt} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                setToastMeta({
                    action:2,
                    msg:"",
                    visible:false,
                    invoked:0,
                })
            }} />
        <div className="flexi" style={{
            width:'100%'
        }}>
            <Wallet isMobile={isMobile} mye={mye} toast={toast} />
            <RatingInfo isMobile={isMobile} mye={mye} playform={mainprop.playform} profile={mainprop.profile} />
            <MonUpdate isMobile={isMobile} mye={mye} isNgt={mainprop.isNgt} move={mainprop.move} />
        </div>
    </div>
    )

}

//${rootDB}${mainprop.classID}/${mainprop.courseID}/scores/${getAuth().currentUser?.email}

const RatingInfo = memo((prop:{mye:myEles,isMobile:boolean,profile:profileEle,playform:FirebaseApp})=>{
    const[rdy, setRdy] = useState(false)
    const mykey = Date.now()
    const mye = new myEles(false)
    const[infos, setInfo] = useState<getPointEle[]>([])

    useEffect(()=>{
        getInfo()
    },[])

    function getInfo(){
        setRdy(false)
        getQuery(collection(getFirestore(),`meta/rating/info`),(task)=>{
            if(task.isSuccessful()){
                let tem:getPointEle[] = []
                task.getResult().docs.forEach((doc,i)=>{
                    tem.push(new getPointEle(doc))
                })
                setInfo(tem)
                setRdy(true)
            }
        })
    }
    

    return <div style={{
        width:prop.isMobile?'100%':400,
        boxSizing:'border-box',
        padding:20,
        height:500,
        margin:10,
        backgroundColor:prop.mye.mycols().white,
        borderRadius:10,
        display:'flex',
        flexDirection:'column'
    }}>
        <mye.Tv text="Rating" />
        <Mgin top={5}/>
        <div style={{
            width:`100%`,
            backgroundColor:prop.mye.mycols().btnstrip,
            borderRadius:10,
            padding:10,
            boxSizing:'border-box',
        }}>
            <prop.mye.BTv text={prop.profile.getPoints().toString()+' Points'} size={20} color={prop.mye.mycols().primarycol} />
        </div>
        <Mgin top={10} />
        <prop.mye.BTv text="Get More" size={16} />
        <Mgin top={10} />
        <div style={{
            width:'100%',
            flex:1
        }}>
            {(rdy && infos.length>0)?<div style={{
                overflowY:'scroll'
            }}>
                {infos.map((info,index)=>{
                    return <PointInfo key={mykey+index} ele={info}/>
                })}
            </div>:<div className="ctr" style={{
                width:'100%',
                height:'100%'
            }}>
                {rdy?<prop.mye.Tv text="Incoming" color={prop.mye.mycols().hint} />:<CircularProgress />}
                </div>}
        </div>
    </div>

    function PointInfo(pp:{ele:getPointEle}){
        const[doInfo, setDoInfo] = useState(false)
        const[load, setLoad] = useState(false)
        const[msg, setMsg] = useState('')
        

        return <div>
            <div style={{
                width:'100%',
                padding:5,
                boxSizing:'border-box',
                display:'flex',
                alignItems:'center'
            }}>
                <div style={{
                    flex:1
                }}>
                    <prop.mye.BTv text={pp.ele.getTitle()} size={14} color={prop.mye.mycols().hint} />
                    <Mgin top={5} />
                    <prop.mye.Tv text={pp.ele.getDesc()} size={12} color={prop.mye.mycols().hint} maxLines={2} />
                </div>
                <div style={{
                    marginLeft:5,
                    marginRight:5
                }} onClick={()=>{

                }}>
                    <ArrowRight className="icon" />
                </div>
            </div>
            <div style={{
                display:doInfo?'flex':'none',
                alignItems:'center',
                justifyContent:'center',
                backgroundColor:'rgba(0,0,0,0.1)',
                position:'absolute',
                top:0,
                left:0,
                width:'100%',
                height:'100%'
            }}>
                <div style={{
                    width:prop.isMobile?'100%':400,
                    padding:20,
                    boxSizing:'border-box',
                    margin:prop.isMobile?20:0,
                    backgroundColor:prop.mye.mycols().white,
                    borderRadius:20,
                    position:'relative'
                }}>
                    {load?<div className="ctr" style={{
                        width:'100%',
                        height:400
                    }}>
                        {msg.length>0?<div className="vlc">
                            <prop.mye.BTv text={msg} size={14} />
                            <Mgin top={10} />
                            <StripBtn txt="CLOSE" smallie onClick={()=>{
                                setDoInfo(false)
                            }} />
                        </div>:<CircularProgress />}
                    </div>:<div>
                        <LrText left={<prop.mye.BTv color={prop.mye.mycols().primarycol} size={18} text={"Get Points"} />} 
                        right={<Close className="icon" onClick={()=>{
                            setDoInfo(false);
                            }} />} />
                        <Mgin top={10}/>
                        <prop.mye.BTv size={16} text={pp.ele.getTitle()} />
                        <Mgin top={10}/>
                        <prop.mye.Tv size={14} text={pp.ele.getDesc()} />
                        <Mgin top={20}/>
                        <prop.mye.Tv size={12} text={pp.ele.hasClaims()?'Claim your points only if you have completed the task':
                        'The points will be automatically applied'} color={prop.mye.mycols().hint} />
                        <Mgin top={5}/>
                        <div style={{
                            width:'100%',
                            display:pp.ele.hasClaims()?undefined:'none'
                        }}>
                            <Btn txt={"CLAIM POINTS NOW"} onClick={()=>{
                                setMsg('')
                                setLoad(true)
                                getFS(docu(getFirestore(prop.playform),pp.ele.getClaim()+getAuth().currentUser!.email),(ptask)=>{
                                    if(ptask.isSuccessful() && ptask.exists()){
                                        if(getData(ptask.getResult(),'zz').length === 0){
                                            const pts = getData(ptask.getResult(),'s')
                                            if(pts.length>0){
                                                updateDoc(prop.profile.doc.ref,{
                                                    pt:prop.profile.getPoints()+parseInt(pts)
                                                }).then(()=>{
                                                    updateDoc(ptask.getResult().ref,{
                                                        zz:'1'
                                                    }).then(()=>{
                                                        window.location.reload()
                                                    })
                                                }).catch((e)=>{
                                                    setLoad(true)
                                                    setMsg('An error occurred')
                                                })
                                            }else{
                                                setLoad(true)
                                                setMsg('Invalid Points Location')
                                            }
                                        }else{
                                            setLoad(true)
                                            setMsg('Already Claimed')
                                        }
                                    }else{
                                        setLoad(true)
                                        setMsg('Points Not Found')
                                    }
                                })
                            }} />
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    
    }

})

const MonUpdate = memo((prop:{mye:myEles,isMobile:boolean, isNgt:boolean, move:(pos:number)=>void})=>{
    const[rdy, setRdy] = useState(false)
    const[blogs,setBlogs] = useState<blogEle[]>([])
    const myKey = Date.now() 
    const mye = new myEles(prop.isNgt)

    useEffect(()=>{
        getBlog()
    },[])

    function getBlog(){
        setRdy(false)
        getDocs(query(collection(getFirestore(),`meta/blogs/dev`),orderBy('t','desc'),limit(3))).then((docs)=>{
            let tem:blogEle[] = []
            docs.docs.forEach((doc)=>{
                tem.push(new blogEle(doc))
            })
            setBlogs(tem)
            setRdy(true)
        }).catch((e)=>{
            console.error(e)
        })
    }

    return <div style={{
        width:prop.isMobile?'100%':400,
        margin:10,
        height:500,
        backgroundColor:prop.mye.mycols().white,
        borderRadius:10,
    }}>
        {(rdy && blogs.length >0)?<div style={{
            width:'100%',
        }}>
            {
                blogs.map((bg,i)=>{
                    return <TabBtn ele={bg} key={myKey+i+0.2456} />
                })
            }
            <Mgin top={20} />
            <div className="hlc" style={{
                width:'100%'
            }} onClick={()=>{
                prop.move(6)
            }}>
                <prop.mye.BTv size={14} text="More Updates" color={prop.mye.mycols().primarycol} />
                <Mgin right={5} />
                <ArrowRight className="icon" />
            </div>
        </div>:<div className="ctr" style={{
            width:'100%',
            height:'100%'
        }}>
            {rdy?<prop.mye.Tv text="No Updates Yet" color={prop.mye.mycols().hint} />:<CircularProgress />}
            </div>}
    </div>
    
    function TabBtn(sprop:{ele:blogEle}){
        return <div style={{
            borderRadius:'30px 8px 8px 30px',
            width: '100%',
            boxSizing:'border-box',
            padding:10,
            backgroundColor: mye.mycol.btnstrip
        }} onClick={()=>{
            goUrl(sprop.ele.getLink())
        }}>
            <mye.BTv text={sprop.ele.getTitle()} size={14} color={mye.mycol.black}/>
            <Mgin top={3}/>
            <mye.Tv text={sprop.ele.getDesc()} size={12} color={mye.mycol.hint}/>
        </div>
    }
})

const Wallet = memo((prop:{mye:myEles,toast:(msg:string,action:number,delay?:number)=>void,isMobile:boolean})=>{
    const mykey = Date.now()
    const mye = new myEles(false)
    const[shTrx, setShowTrx] = useState(false)
    const[cwRan, setCwRan] = useState(false)
    const[isIn, setIsin] = useState(true)
    const[bal, setBal] = useState('')
    const[trxs, setTrxs] = useState<trxEle[]>([])

    useEffect(()=>{
        getBal()
    },[])

    function getBal(){
        //get Balance
        getFS(docu(getFirestore(),`GTW/MND/uBal/${getUid()}`),(task)=>{
            if(task.isSuccessful()){
                if(task.exists()){
                    setBal(`NGN ${getData(task.getResult(),'m')}`)
                    //--GET TRX
                    getTrx(isIn)
                }else{
                    if(!cwRan){
                        setCwRan(true)
                        const gtw_cw = httpsCallable(getFunctions(),'gtw_cw')
                        gtw_cw({
                            cid:'MND',
                            uid: getUid()
                        }).then((result)=>{
                            const data = result.data as fncRes
                            let rs = data.s
                            if(!rs.startsWith('s')){
                                prop.toast("Can't create your wallet for now",0)
                            }
                            getBal()
                        }).catch((e)=>{
                            prop.toast("Can't create your wallet for now",0)
                        })
                    }else{
                        prop.toast("Can't create your wallet for now",0)
                    }
                }
            }
        }); 
    }

    function getTrx(isIn:boolean){
        setShowTrx(false)
        getDocs(query(collection(getFirestore(),`GTW/MND/txh`),where(
            isIn?'t':'f','==',getUid()
        ))).then((docs)=>{
            const tem:trxEle[] = []
            docs.docs.forEach((doc, index)=>{
                const ele = new trxEle(doc)
                tem.push(ele)
            })
            setTrxs(tem)
            setShowTrx(true)
        }).catch((e)=>{
            console.error(e)
        })
    }

    return <div style={{
        width:prop.isMobile?'100%':400,
        boxSizing:'border-box',
        padding:20,
        height:500,
        margin:10,
        backgroundColor:prop.mye.mycols().white,
        borderRadius:10,
        display:'flex',
        flexDirection:'column'
    }}>
        <mye.Tv text="Wallet" />
        <Mgin top={5}/>
        <div style={{
            width:`100%`,
            height:70,
            backgroundColor:prop.mye.mycols().btnstrip,
            borderRadius:10,
            padding:10,
            boxSizing:'border-box'
        }}>
            <prop.mye.BTv text={bal} size={20} color={prop.mye.mycols().primarycol} />
        </div>
        <Mgin top={10} />
        <div className="hlc">
            <Tablet mye={prop.mye} sel={isIn} txt="IN" ocl={()=>{
                setIsin(true)
                getTrx(true)
            }} />
            <Mgin right={5} />
            <Tablet mye={prop.mye} sel={!isIn} txt="OUT" ocl={()=>{
                setIsin(false)
                getTrx(false)
            }} />
        </div>
        <Mgin top={10} />
        <div style={{
            width:'100%',
            flex:1
        }}>
            {(shTrx && trxs.length>0)?<div style={{
                overflowY:'scroll'
            }}>
                {trxs.map((tx,index)=>{
                    return <TrxMini key={mykey+index} ele={tx} mye={prop.mye}/>
                })}
            </div>:<div className="ctr" style={{
                width:'100%',
                height:'100%'
            }}>
                {shTrx?<prop.mye.Tv text="No Transactions Yet" color={prop.mye.mycols().hint} />:<CircularProgress />}
                </div>}
        </div>
    </div>

    function TrxMini(prop:{ele:trxEle,mye:myEles}){

        return <div style={{
            width:'100%',
            padding:5,
            boxSizing:'border-box',
        }}>
            <prop.mye.BTv text={`NGN ${prop.ele.getAmt()}`} size={14} color={prop.mye.mycols().hint} />
            <Mgin top={3} />
            <prop.mye.Tv text={`FROM: ${prop.ele.getFrom()}`} />
            <Mgin top={2} />
            <prop.mye.Tv text={`FOR: ${prop.ele.getFor()}`} />
        </div>

    }

})

