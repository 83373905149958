import { useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail, createUserWithEmailAndPassword, sendEmailVerification, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import {useNavigate, useLocation} from "react-router-dom"
import gcon from "../assets/google.png"
import { CircularProgress } from "@mui/material";
import useWindowDimensions from "../helper/dimension";
import Toast from "./toast/toast";
import { BoldText, Btn, CloseBtn, EditText, HeadText, isEmlValid, LrText, Mgin, MyCols, myEles, StripBtn, StripBtnImg, TextBox } from "../helper/general";
import { FirebaseApp } from "firebase/app";
import { doc as docu } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFS, setFS } from "../helper/firehelpers";
import { getFirestore } from "firebase/firestore";
import { Info, Lock } from "@mui/icons-material";
import { isMobile } from "react-device-detect";

function useQuery(){
    return new URLSearchParams(useLocation().search);
}

export default function SignIn(prop:{isNgt:boolean,isApp:boolean}){
    const[ran, setRan] = useState(false);
    const qry = useQuery();
    const rdr  = qry.get('rdr')||""
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    const[isreg, setIsReg] = useState(false);
    const[eml, setEml] = useState(qry.get("eml")||"");
    const[pwd1, setPwd1] = useState("");
    const[pwd2, setPwd2] = useState("");
    const[verifEmail, setVerifEmail] = useState(false);
    
    //--MAILCHIMP
    const[umail, setUmail] = useState('')
    const[uname, setUname] = useState('')
    const[uuid, setUuid] = useState('')
    const[askName, setAskName] = useState(false)

    const navigate = useNavigate();
    const dimen = useWindowDimensions()
    const mye = new myEles(prop.isNgt)

    useEffect(()=>{

        if(!ran){
            setRan(true);
            if(getAuth().currentUser!==null){
                // if(getAuth().currentUser?.emailVerified){
                //     getAuth().signOut();
                // }else{
                //     verifyEmail();
                // }
                getAuth().signOut();
            }
            setIsReg(qry.get('stat')==='n')
            if(prop.isNgt){
                document.body.classList.toggle("dark-theme")
            }
        }
        
    }, []);

    function verifyEmail(){
        setLoad(true);
        setVerifEmail(true)
        getAuth().currentUser?.reload().then(()=>{
            console.log(getAuth().currentUser?.emailVerified)
            sendEmailVerification(getAuth().currentUser!).then(()=>{
                setLoad(false);
                toast("Email link sent",1);
            }).catch((e)=>{
                console.log(e)
                setLoad(false);
                toast("An error occured",0)
            })
        }).catch((e)=>{
            console.error(e)
            setLoad(false);
            toast("An error occured",0)
        })
    }

    function mailchimp(mail:string){
        setUmail(mail)
        setVerifEmail(false)
        setAskName(true)   
    }

    function enlist_mail(){
        setAskName(false)
        //Create wallet
        setLoad(true);
        const gtw_cw = httpsCallable(getFunctions(),'gtw_cw')
        gtw_cw({
            cid:'MND',
            uid: 'd'+uuid,//Dev wallet
            m:umail,
            n:uname
        }).then((result)=>{
            setLoad(false)
            const data = result.data as fncRes
            let rs = data.s
            if(rs.startsWith('s')){
                console.log("CHIMPED")
            }else{
                console.log("CHIMP FAILED")
            }
            navigate(`/${rdr}`)
        }).catch((e)=>{
            setLoad(false)
            toast("An error occurred. Please try again",0)
        })
    }

    const[load, setLoad]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    function toast(msg:string, action:number,delay?:number){
        var _delay = delay || 5000
        setToastMeta({
            action: action,
            msg: msg,
            visible:true,
            invoked: Date.now()
        })
        clearTimeout(timy.timer)
        setTimy({
            timer:setTimeout(()=>{
                if(Date.now()-toastMeta.invoked > 4000){
                    setToastMeta({
                        action:2,
                        msg:"",
                        visible:false,
                        invoked: 0
                    })
                }
            },_delay)
        });
    }


    
    return (
        <div  data-theme={prop.isNgt?'dark':'light'} style={{display:"flex",flexDirection:"column", alignItems:"center",backgroundColor:MyCols(prop.isNgt).bkg, height:dimen.height}}>
            <div className="prgcont" style={{display:load?"flex":"none"}}>
                <CircularProgress/>
            </div>
            <Toast isNgt={prop.isNgt} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                    setToastMeta({
                        action:2,
                        msg:"",
                        visible:false,
                        invoked:0,
                    })
                }} />
            <div className="incolumn" style={{width:dimen.dsk?500:(dimen.width-40), padding:20, display:verifEmail?"none":"flex"}}>
                <Mgin top={15} />
                <HeadText isNgt={prop.isNgt} text={`Welcome To Monagree`} />
                <Mgin top={3} />
                <TextBox isNgt={prop.isNgt} text={`Let's sign you ${isreg?'up':'in'}`} size={16} />
                <Mgin top={40}/>
                <EditText value={eml} isNgt={prop.isNgt} hint="Email Address" eml={true} min={7} max={50} recv={(val)=>{
                    setEml(val.trim())
                }}/>
                <Mgin top={15}/>
                <EditText value={pwd1} isNgt={prop.isNgt} hint="Password" pwd={true} min={6} max={25} recv={(val)=>{
                    setPwd1(val.trim())
                }}/>
                
                <div style={{
                    display:isreg?"unset":"none",
                }}>
                    <Mgin top={15}/>
                    <EditText value={pwd2} isNgt={prop.isNgt} hint="Once more so we get it right" pwd={true} min={6} max={25} recv={(val)=>{
                        setPwd2(val.trim())
                    }}/>
                </div>
                {/* <div style={{
                    padding:5,
                    backgroundColor:mye.mycols().redstrip,
                    borderRadius:5,
                    marginTop:10
                }}>
                    <Info style={{
                        fontSize:20,
                        color:'black'
                    }} />
                    <Mgin right={5} />
                    <mye.Tv text="There seems to be an issue with email verification. Please use google sign in for now" size={12} />
                </div> */}
                <div style={{
                    display:isreg?"none":"unset"
                }}>
                    <Mgin top={15}/>
                    <div className="inrow">
                        <TextBox isNgt={prop.isNgt} text="Forgot password ?" size={14}/>
                        <Mgin right={10}/>
                        <TextBox isNgt={prop.isNgt} text="Reset It" size={14} color={MyCols(prop.isNgt).primarycol} onClick={()=>{
                            if(eml==="" || !isEmlValid(eml)){
                                toast("Please enter email and retry", 2);
                                return;
                            }
                            setLoad(true);
                            sendPasswordResetEmail(getAuth(),eml).then(()=>{
                                setLoad(false);
                                toast("A password reset link was just mailed to you. Go ahead and click it",1,20000)
                            }).catch(()=>{
                                setLoad(false);
                                toast("An error occurred", 0)
                            })
                        }}/>
                    </div>
                </div>
                <Mgin top={25}/>
                <Btn txt={isreg?"REGISTER":"LOGIN"} onClick={()=>{
                    //toast('Please use google sign in for now',0)
                    if(eml==="" || !isEmlValid(eml)){
                        toast("Invalid Email", 2);
                        return;
                    }
                    if(pwd1.length <6){
                        toast("Invalid Password. Too short", 2);
                        return;
                    }
                    if(isreg){
                        if(pwd1!==pwd2){
                            toast("Password mismatch, please confirm", 2);
                            return;
                        }
                        setLoad(true);
                        createUserWithEmailAndPassword(getAuth(),eml,pwd1).then((user)=>{
                            setLoad(false)
                            setUuid(user.user.uid)
                            // if(user.user.emailVerified){
                            //     //--REFERRAL
                            //     mailchimp(user.user.email!)
                            // }else{
                            //     verifyEmail()
                            // }
                            navigate(`/${rdr}`)
                        }).catch((e)=>{
                            console.error(e);
                            setLoad(false);
                            if(e.code.startsWith('auth/account-exists') || e.code.startsWith('auth/email-already-in-use')){
                                toast("Account exists, please login instead",0,6000);
                            }else if(e.code.startsWith('auth/invalid')){
                                toast("Invalid Credentials",0);
                            }else{
                                toast("An error occurred. Have you registered (below)? ",0);
                            }
                        });
                    }else{
                        setLoad(true);
                        signInWithEmailAndPassword(getAuth(),eml,pwd1).then((user)=>{
                            // if(user.user.emailVerified){
                            //     //--REFERRAL
                            //     navigate(`/${rdr}`)
                            // }else{
                            //     verifyEmail()
                            // }
                            navigate(`/${rdr}`)
                        }).catch((e)=>{
                            console.log(e)
                            setLoad(false);
                            if(e.code.startsWith('auth/invalid')||e.code.startsWith('auth/wrong-password')){
                                toast("Invalid Credentials, you can change password",0);
                            }else if(e.code.startsWith('auth/user-not-found')){
                                toast("Confirm password to register",2);
                                setIsReg(true)
                            }else{
                                toast("An error occurred",0);
                            }
                        });   
                    }

                }}/>
                <Mgin top={15}/>
                <div style={{alignSelf: "center", width: 50, height: 5, borderRadius: 5, backgroundColor: MyCols(prop.isNgt).btnstrip5}}></div>
                <div style={{
                    display:prop.isApp?"none":"unset"
                }}>
                    <Mgin top={10}/>
                    <StripBtnImg txt={`${isreg?'REGISTER':'LOGIN'} WITH GOOGLE`} img={gcon} onClick={()=>{
                        setLoad(true)
                        const provider = new  GoogleAuthProvider();
                        signInWithPopup(getAuth(), provider).then((result)=>{
                            setLoad(false)
                            if(result.user.metadata.creationTime === result.user.metadata.lastSignInTime){
                                setUuid(result.user.uid)
                                //mailchimp(result.user.email!)
                                navigate(`/${rdr}`)
                            }else{
                                navigate(`/${rdr}`)
                            }
                        }).catch((e)=>{
                            setLoad(false)
                            if(e.code.startsWith('auth/account-exists')){
                                toast("Account exists. Please use email/password method",0,8000);
                            }else{
                                toast("An error occurred. Please use email instead",0);
                            }
                        });
                    }}/>
                </div>
                <Mgin top={10}/>
                <StripBtn txt={isreg?"LOGIN":"REGISTER"} onClick={()=>{
                    setIsReg(!isreg);                   
                }}/>
            </div>
            <div className="vlc" style={{
                display:(verifEmail)?undefined:"none",
                width:dimen.width,
                height:dimen.height,
                position:'absolute',
                backgroundColor:'rgba(0,0,0,0.1)'
            }}>
                <div style={{width:dimen.dsk?500:(dimen.width-40), padding:20}}>
                    <Mgin top={20}/>
                    <BoldText isNgt={prop.isNgt} size={20} text="One more thing"/>
                    <Mgin top={10}/>
                    <TextBox wrapit={true} isNgt={prop.isNgt} text={`We just sent an email verification link to ${eml}. Please click the link and come back to click DONE.` } />
                    <Mgin top={20} />
                    <Btn txt="DONE" onClick={()=>{
                        setLoad(true);
                        getAuth().currentUser?.reload().then(()=>{
                            setLoad(false)
                            if(getAuth().currentUser?.emailVerified){
                                mailchimp(getAuth().currentUser?.email!)
                            }else{
                                toast("Email not yet verified",0)
                            }
                        })
                    }}/>
                    <Mgin top={20} />
                    <StripBtn txt="RESEND LINK" onClick={()=>{
                        verifyEmail()
                    }} />
                </div>
            </div>
            <div style={{
                width:dimen.width,
                height:dimen.height,
                backgroundColor:"rgba(0,0,0,0.2)",
                position:"absolute",
                top:0,
                display:askName?'unset':'none',
            }}>
                <div style={{
                    position:"absolute",
                    bottom:0,
                    right:0,
                    width:dimen.width-40,
                    padding:20,
                    backgroundColor:MyCols(prop.isNgt).bkg,
                    borderRadius:"10px 10px 0px 0px"
                }}>
                    <LrText left={<BoldText text="Quick, Your Name" isNgt={prop.isNgt} size={20}  />} right={<CloseBtn onClick={()=>{
                        setAskName(false)
                    }} />}/>
                    <Mgin top={20}/>
                    <EditText hint="Your First Name" isNgt={prop.isNgt} min={3} max={20} value={uname} recv={(val)=>{
                        setUname(val.trim())
                    }} />
                    <Mgin top={10}/>
                    <Btn txt="PROCEED" onClick={()=>{
                        if(uname.length<3){
                            toast("Please enter name",2)
                            return;
                        }
                        enlist_mail()
                    }} />
                </div>
            </div>
        </div>
    )
}




//For re-auths
export function ReAuth(prop:{isMobile:boolean,mye:myEles,finise:(ok:boolean)=>void}){
    const[pwd,setPwd] = useState('')
    const[load,setLoad] = useState(false)


    return <div className="ctr" style={{
        position:'fixed',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        backgroundColor:'rgba(0,0,0,0.1)'
    }}>
        <div className="vlc" style={{
            padding:20,
            margin:20,
            width:prop.isMobile?'100%':300,
            backgroundColor: prop.mye.mycols().white,
            borderRadius:10,
        }}>
            <Lock className="icon" />
            <Mgin top={10}/>
            <prop.mye.BTv text="Authenticate" size={20}  />
            <Mgin top={15}/>
            <EditText value={pwd} isNgt={prop.mye.isNgt} hint="Password" pwd={true} min={6} max={25} recv={(val)=>{
                setPwd(val.trim())
            }}/>
            <Mgin top={10}/>
            <Btn txt={load?'JUST A SEC':"PROCEED"} onClick={()=>{
                if(load){
                    return;
                }
                if(pwd.length <6){
                    return;
                }
                setLoad(true)
                reauthenticateWithCredential(getAuth().currentUser!, EmailAuthProvider.credential(getAuth().currentUser!.email!,pwd)).then((usr)=>{
                    prop.finise(true)
                }).catch((e)=>{
                    console.error(e)
                    prop.finise(false)
                    getAuth().signOut()
                })
            }} />
        </div>
    </div>


}




export interface fncRes {
    s: string;
}