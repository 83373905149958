import { getAuth } from "firebase/auth";
import { DocumentSnapshot, QuerySnapshot,DocumentReference,CollectionReference, getDoc, query, getDocs,
          setDoc, 
          doc,
          getFirestore,
          collection} from "firebase/firestore";
import { getStorage, ref, StorageReference } from "firebase/storage";


export function getData(doc:DocumentSnapshot, key:string):string{
    if(doc.data()=== undefined){
        return "";
    }
    var obj = doc.data()!
    var rsp = obj[key] ?? "";
    return rsp.toString();
}

export function getFS(ref:DocumentReference,task:(task:fsTask)=>void){
  getDoc(ref).then((snap)=>{
    task(new fsTask(true,undefined,snap))
  }).catch((e)=>{
    task(new fsTask(false,e,undefined));
  });
}

export function getQuery(ref:CollectionReference,task:(task:queryTask)=>void){
  getDocs(query(ref)).then((snaps)=>{
    task(new queryTask(true,undefined,snaps))
  }).catch((e)=>{
    task(new queryTask(false,e,undefined));
  });
}

export function setFS(ref:DocumentReference,data:Object,task:(task:fsSet)=>void){
  setDoc(ref,data).then(()=>{
    task(new fsSet(true,undefined));
  }).catch((e)=>{
    task(new fsSet(false,e));
  });
}




class fsSet{
    _success:boolean;
    _e:any;
    constructor(_success:boolean, _e:any){
        this._success = _success;
        this._e = _e; 
    }
    get getEr():string{
      return getErrMsg(this._e);
    }
  }



  class fsTask{
    _success:boolean;
    _exists:boolean;
    _snapshot?:DocumentSnapshot;
    _e:any;
  
    constructor(_success:boolean,_e:any,_snapshot?:DocumentSnapshot){
        this._success = _success;
        this._snapshot = _snapshot;
        this._e = _e;
        this._exists = (!_success?false:_snapshot!.exists());
    }
  
    getVRes(){
      return getData(this._snapshot!, "v");
    }

    getEr(){//NEEDS CORRECTION IN getEC
      return getErrMsg(this._e);
    }

    isSuccessful(){
      return this._success;
    }

    exists(){
      return this._exists;
    }

    getResult(){
      return this._snapshot!;
    }

  }


class queryTask{
    _success:boolean;
    _exists:boolean;
    _snapshot?:QuerySnapshot;
    _e:any;
  
    constructor(_success:boolean,_e:any,_snapshot?:QuerySnapshot){
        this._success = _success;
        this._snapshot = _snapshot;
        this._e = _e;
        this._exists = (!_success?false:(_snapshot!.docs.length!=0));
    }
  
    getEr(){//NEEDS CORRECTION IN getEC
      return getErrMsg(this._e);
    }

    isSuccessful(){
      return this._success;
    }

    getResult(){
      return this._snapshot!;
    }

  }

  
function getEC(e:any):number{
    if(e.startsWith('[firebase_auth/network-request-failed]')){
      return 0;
    }
    if(e.startsWith('[firebase_auth/wrong-password]')){
      return 1;
    }if(e.startsWith('[firebase_auth/too-many-requests]')){
      return 2;
    }
    if(e.startsWith('[firebase_auth/user-not-found]')){
      return 4;
    }
    return 3;
  }
  
  function getErrMsg(e:any):string{
    if (getEC(e) === 0) {
      return 'Network Error';
    } else if (getEC(e) === 2) {
     return 'Too many attempts. To protect this account, we will not allow sign in for a while. Please try again later. ';
    }else if (getEC(e) === 1) {
     return 'Invalid Credentials';
    } else {
      return 'An error occurred. Please try again';
    }
  }


  //--------Miscellaneous

  export function logd(){
    return getAuth().currentUser!==null;
  }

  export function getUid():string{
    if(logd()){
      return localStorage.getItem('cuid')??'';
    }else{
      return "";
      //Launch Sign in 
    }
  }

  export function setUid(uid:string){
    localStorage.setItem('cuid',uid)
    localStorage.removeItem('sc')
  }

  