

import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorCont, TextBox } from "../helper/general";
import Toast from "./toast/toast";




export default function Privacy(){

    const[myKey,setMyKey] = useState(0)
    const[ran, setRan] = useState(false);
    const[rfrs,setRfrs] = useState(false)//refresh allowed?

    useEffect(()=>{
        //get states
        if(!ran){
            setRan(true);
            setMyKey(Date.now())
            //Get Status and listen
            
        }
    },[])

    const[load, setLoad]=useState(false)
    const[error, setError]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    function toast(msg:string, action:number,delay?:number){
      var _delay = delay || 5000
      setToastMeta({
          action: action,
          msg: msg,
          visible:true,
          invoked: Date.now()
      })
      clearTimeout(timy.timer)
      setTimy({
          timer:setTimeout(()=>{
              if(Date.now()-toastMeta.invoked > 4000){
                  setToastMeta({
                      action:2,
                      msg:"",
                      visible:false,
                      invoked: 0
                  })
              }
          },_delay)
      });
    }


    return (<div >
            <ErrorCont isNgt={false} visible={error} retry={()=>{
                    //todo smth
            }}/>
            <div className="prgcont" style={{display:load?"flex":"none"}}>
                <CircularProgress/>
            </div>
            <Toast isNgt={false} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                    setToastMeta({
                        action:2,
                        msg:"",
                        visible:false,
                        invoked:0,
                    })
                }} />
            <div className="cont" style={{display:"flex", flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                <TextBox isNgt={false} text='MONAGREE USER PRIVACY'/>
                <TextBox isNgt={false} text='Monagree is dedicated to helping you build the best way. We do not share your personal information with anyone'/>
            </div>
        </div>
        )
    
}